import React from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { BsFillGearFill } from "react-icons/bs"

const RouteGuard = ({ children, adminRoute, ...rest }) => {
  function hasJWT() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user && !!user.token
  }

  function isAdmin() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user && user.dataValues.role > 0
  }

  return (
    <Route {...rest}
      render={() => (
        hasJWT() ? ((adminRoute && isAdmin()) || !adminRoute ? (
          <>
            <Link to={"/user/settings"} className={"absolute top-0 right-0 p-4"}>
              <BsFillGearFill className={"text-zinc-400 text-2xl"}/>
            </Link>
            {children}
          </>

        ) : <Redirect to={{ pathname: "/" }} /> ) : <Redirect to={{ pathname: "/login" }} />
      )}
    />
  );
};

export default RouteGuard;