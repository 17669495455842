const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const obtenerAdminProductos = () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/admin/producto`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const obtenerAdminVisitas = () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/admin/visita`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const eliminarAdminVisitas = (visitaId) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/admin/visita/${visitaId}`, {
      method: "DELETE"
    })
      .then(r => {
        if(r.ok) {
          return r.json()
        }
        reject()
      })
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const obtenerAdminComerciales = () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/admin/comercial`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const createComercial = (comercialData) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/admin/comercial`, {
      method: "POST",
      body: JSON.stringify(comercialData),
      headers: { "Content-Type": "application/json","Accept":"application/json" },
    })
      .then(r => {
        if(r.ok) {
          return r.json()
        }
        reject()
      })
      .then(response => resolve(response.id))
      .catch(reject)
  })
}