import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import usePageVisibility from "../../hooks/usePageVisibility"
import WishlistSerie from "../../components/wishlist-serie";
import AuthService from "../../services/auth-service";
import ConfirmDialog from "../../components/confirm-dialog";
import {useTranslation} from "react-i18next";

const VisitaPage = ({obtenerVisita, eliminarSerie, finalizarVisita}) => {
  const [visitaData, setVisitaData] = useState(null)
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: ""
  });
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  const { visitaId } = useParams()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const pageVisibility = usePageVisibility();

  const handleGoToIntendedPage = useCallback(
    (location) => history.push(location),
    [history]
  );

  useEffect(() => {
    if (triggerExit.onOk || (visitaData && visitaData.finalizada) || (triggerExit.path.includes("visita") && !triggerExit.path.includes("visitas")) || triggerExit.path.includes("user/settings")) {
      handleGoToIntendedPage(triggerExit.path);
    }
    const unblock = history.block((location) => {
      if (location.pathname.includes("visitas") && (!location.pathname.includes("user/settings") || !location.pathname.includes("visita"))) {
        setVisibleDialog(true);
      }
      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [handleGoToIntendedPage, history, triggerExit.onOk, triggerExit.path]);

  useEffect(() => {
    if (visitaData) {
      localStorage.setItem("tau-pamesa-current-visita", visitaData.id)
    }
  }, [visitaData])

  useEffect(() => {
    obtenerVisita(visitaId)
      .then((visita) => {
        visita.items = parseVisitaItems(visita)
        setVisitaData(visita)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [visitaId, pageVisibility, obtenerVisita])

  const parseVisitaItems = (visita) => {
    return visita.series.map(s => s.nombre)
  }

  const eliminaSerie = (serie) => {
    eliminarSerie(visitaId, serie)
      .then(() => {
        obtenerVisita(visitaId)
          .then((visita) => {
            visita.items = parseVisitaItems(visita)
            setVisitaData(visita)
          })
          .catch((e) => {
            console.error(e)
          })
      }).catch((e) => {
        console.error(e)
      })
  }

  const handleCloseList = () => {
    finalizarVisita(visitaId, i18n.language)
      .then(() => {
        obtenerVisita(visitaId)
          .then((visita) => {
            visita.items = parseVisitaItems(visita)
            setVisitaData(visita)
          })
          .catch((e) => {
            console.error(e)
          })
      }).catch((e) => {
        console.error(e)
      })
  }

  const handleNewVisit = () => {
    history.replace("/crear-visita")
  }

  const handleSignOut = () => {
    AuthService.logout()
    history.replace("/")
  }

  const editSerie = (serie) => {
    history.push(`/visita/${visitaId}/${serie}`)
  }

  return visitaData && (
    <>
      {(!visitaData.finalizada) && (
        <div className={"container mx-auto p-4 max-w-screen-md"}>
          <div className={"grid grid-cols-1 mb-5"}>
            <div className={"flex justify-center"}>
              <h2 className="text-3xl md:text-3xl font-medium leading-tight text-black text-center">{t("Visita de")}<br/><span className={"font-bold uppercase"}>{visitaData.cliente}</span></h2>
            </div>
          </div>

          <div className={"grid grid-cols-1 mb-3"}>
            {visitaData.items && visitaData.items.length === 0 && (
              <div className={"flex flex-col justify-center items-center mt-10 text-neutral-800"}>
                <span className={"my-3 mt-5 text-center text-2xl sm:text-2xl"}>{t("La lista está vacía.")}</span>
                <span className={"my-3 mt-4 text-center text-2xl sm:text-2xl"}>{t("Escanea productos para añadirlos.")}</span>
                <img src={"/assets/images/nfc.svg"} alt="nfc" className={"w-2/5 mt-3"} />
              </div>
            )}
            {visitaData.items && visitaData.items.length > 0 && (
              <>
                <div className={"flex flex-row justify-center items-center px-5"}>
                  <span className={"text-lg text-neutral-700 w-3/5"}>{t("Escanea productos para añadirlos.")}</span>
                  <img src={"/assets/images/nfc.svg"} alt="nfc" className={"w-1/4"} />
                </div>

                <div className={"grid grid-cols-1 my-3 px-2"}>
                  <span className={"text-neutral-400"}>{t("Series añadidas")}</span>
                </div>
                <div className={"h-[47vh] max-h-[47vh] overflow-y-scroll no-scrollbar flex flex-col justify-start px-2"}>
                  {visitaData.items.map(serie =>
                    <WishlistSerie key={serie} serie={serie} eliminaSerie={eliminaSerie} editSerie={editSerie}/>
                  )}
                </div>
              </>
            )}
            {visitaData.items && visitaData.items.length > 0 && (
              <div className={"relative grid grid-cols-1 my-3"}>
                <div className={"flex flex-row justify-center my-5"}>
                  <button
                    className={"w-3/4 mt-3 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
                    type="button" onClick={handleCloseList}>
                    {t("Finalizar visita")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {visitaData.finalizada && (
        <>
          <div className={"container mx-auto p-4 max-w-screen-md h-[71vh]"}>`
            <div className={"h-full flex flex-col justify-center items-center"}>
              <h2 className={"text-center text-2xl font-bold uppercase my-5"}>{t("visita finalizada")}</h2>
              <p className={"text-center text-lg my-3"}>{t("¡Gracias por visitarnos y confiar en nosotros!")}</p>
              <p className={"text-center text-md my-3"}>{t("En breve recibirás un email con el resúmen de tus artículos seleccionados.")}</p>
            </div>
          </div>
          <div className={"relative grid grid-cols-1 my-3"}>
            <div className={"flex flex-row justify-center my-1"}>
              <button
                className={"w-3/4 py-3 rounded-md bg-redPamesa text-white text-md font-bold shadow-drop-bottom uppercase"}
                type="button" onClick={handleNewVisit}>
                {t("Comenzar nueva visita")}
              </button>
            </div>
            <div className={"flex flex-row justify-center my-1"}>
              <button
                className={"w-3/4 py-3 text-zinc-90 text-sm font-bold"}
                type="button" onClick={handleSignOut}>
                {t("Salir de la App")}
              </button>
            </div>
          </div>
        </>
      )}
      <ConfirmDialog
        title={"Salir de la visita"}
        si={"Continuar"}
        no={"Salir"}
        open={isVisibleDialog}
        onClose={() => setVisibleDialog(false)}
        onConfirm={() => {}}
        onNoConfirm={() => {
          setTriggerExit((obj) => ({
            ...obj,
            onOk: true
          }));
          setVisibleDialog(false);
        }}
      >
        <p>{t("Está a punto de salir de la sesión.")}</p>
        <p>{t("¿Confirmar?")}</p>
      </ConfirmDialog>
    </>
  )
}

export default VisitaPage;