import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import esTranslations from "./i18n/i18n-es.json";
import enTranslations from "./i18n/i18n-en.json";
import frTranslations from "./i18n/i18n-fr.json";

const resources = {
  ...esTranslations,
  ...enTranslations,
  ...frTranslations
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;