import React from "react"
import { useHistory } from "react-router-dom"
import { HiArrowLeft } from "react-icons/hi"

const Header = ({ shouldNotDisplayBackButton }) => {
  const history = useHistory()

  const handleOnBack = () => {
    //if (onBack) return onBack()
    //history.push("/visitas")
    history.goBack()
  }

  return (
    <div className={"grid grid-cols-6 mb-2"}>
      <div className={"col-span-1 flex flex-row justify-center"}>
        {!shouldNotDisplayBackButton && (
          <button onClick={handleOnBack}>
            <HiArrowLeft className={"text-3xl text-neutral-400"} />
          </button>
        )}
      </div>
      <div className={"col-span-4 flex flex-row justify-center"}>
        <div role="button" tabIndex={-99}
          onClick={() => {}}
          onKeyDown={() => {}}
          className={"flex flex-row justify-center"}
        >
          <img src={"/assets/images/logo.svg"} alt="logo" className={"w-2/5"} />
        </div>
      </div>
    </div>
  )
}

export default Header
