import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AuthService from "../../services/auth-service";
import ConfirmDialog from "../../components/confirm-dialog";

function SettingsPage() {
  const [logOutPopup, setLogOutPopup] = useState(false)
  const [isComercial, setIsComercial] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()

  const comerciales = [
    "agomez@pamesa.com",
    "aortega@pamesa.com",
    "ahernandez@pamesa.com",
    "cgutierrez@pamesa.com",
    "cayetano.garcia@pamesa.com",
    "egomez@pamesa.com",
    "esalazar@pamesa.com",
    "emistraletti@pamesa.com",
    "fathi@pamesa.com",
    "fortiz@pamesa.com",
    "hgrau@pamesa.com",
    "aoron@pamesa.com",
    "isaborit@pamesa.com",
    "imilian@pamesa.com",
    "jsalvador@pamesa.com",
    "jdiaz@pamesa.com",
    "jarodriguez@pamesa.com",
    "jasubies@pamesa.com",
    "jlcrespo@pamesa.com",
    "jmllaneza@pamesa.com",
    "jmclemente@pamesa.com",
    "jmateu@pamesa.com",
    "jrocha@pamesa.com",
    "lchaume@pamesa.com",
    "mantoli@pamesa.com",
    "mariagarcia@pamesa.com",
    "ngarcia@pamesa.com",
    "nramirez@pamesa.com",
    "nubeda@pamesa.com",
    "pcorreas@pamesa.com",
    "ppenas@pamesa.com",
    "pgil@pamesa.com",
    "pcayuela@pamesa.com",
    "ssanchez@pamesa.com",
    "sdonev@pamesa.com",
    "tcano@pamesa.com",
    "ajgasco@pamesa.com",
    "vroca@pamesa.com",
    "vgarcia@pamesa.com",
    "vmendez@pamesa.com",
    "sahilnegoser@hotmail.fr",
    "pedro.mg.costa@gmail.com",
    "lars@ljscanspain.com",
    "geraldine@tegim.be",
    "laurence@tegim.be",
    "charly@tegim.be",
    "info@nwaudek.com",
    "henry.nylund@nwaudek.com",
    "ewelina@comet-bv.com",
    "cretienne@comet-bv.com",
    "jeroen@comet-bv.com",
    "rick@comet-bv.com",
    "allerdave@gmail.com",
    "oscar@meglioli.eu",
    "d.zangonetile@gmail.com",
    "paolomontanari@mac.com",
    "matteo.twintiles@gmail.com",
    "tvk@stroysnabbusiness.com",
    "info@stroysnabbusiness.com",
    "igors.obuhovskis@gmail.com",
    "nasirov@formatoo.ru",
    "e.remond34@gmail.com",
    "augusto.ferreira@free.fr",
    "carrasco.olivier@gmail.com",
    "stephane.coudert@orange.fr",
    "audreybougeant@orange.fr",
    "jmateu@pamesa.com",
    "gcircati@gmail.com",
    "orel@held-agencies.com",
    "office@held-agencies.com",
    "alpisa@aol.com",
    "pnventalon@gmail.com",
    "e.ventalon@icloud.com",
    "hmarini@orange.fr",
    "agencecommercialebeaufort@orange.fr",
    "martinlaurent57@gmail.com",
    "yannick.prosdocimi@gmail.com",
    "clare.baldwin@cbtileagency.co.uk",
    "martin.baldwin@cbtileagency.co.uk",
    "GlennS@Armatile.com",
    "mastromarco.agente@gmail.com",
    "massimo.ruata@gmail.com",
    "info@rappresentanzemauro.it",
    "francesco.zucconi@tiscali.it",
    "gavidemasnc@gmail.com",
    "stefanopedrazzi1@gmail.com",
    "gmoncalieri@gmail.com",
    "luca.bertoni@alice.it",
    "agenziaromani@gmail.com",
    "antoniocosoleto58@gmail.com",
    "info@sintesiad.com",
    "info@agenziadistribuzione.com",
    "info@pellegrinorappresentanze.it",
    "alessandromusacco@gmail.com",
    "silvanodaloisio@alice.it",
    "office@mstfliesen.com",
    "zollinger@sariker.ch",
    "dri@sariker.ch",
    "hv-mittenentzwei@t-online.de",
    "agentur.spohler@gmail.com",
    "donath-guenter@t-online.de",
    "export@tileint.com",
    "thibault@mglexport.fr",
    "oparttiles@gmail.com",
    "kresimir.senjic@gmail.com",
    "bgtiles@gmail.com",
    "gkyprianou@sofocleskyprianou.com",
    "rocio@tiles.agency",
    "pkrol777@gmail.com"
  ]

  useEffect(() => {
    const user = AuthService.getCurrentUser().dataValues
    const comercialUsername = user.username
    setIsComercial(comerciales.includes(comercialUsername))
  }, [])

  const handleLogOut = () => {
    AuthService.logout()
    setLogOutPopup(false)
    window.location.replace("/login")
  }

  return (
    <div className={"container mx-auto py-2 px-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-1"}>
          <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center uppercase">{t("Configuración")}</h2>
        </div>
      </div>
      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center items-center my-5"}>
          {!isComercial && (
            <button
              className={"w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
              type="button" onClick={() => {history.push("/user/profile")}}>
              {t("Cuenta")}
            </button>
          )}
          <button
            className={"w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
            type="button" onClick={() => {history.push("/visitas")}}>
            {t("Historial visitas")}
          </button>
          <button
            className={"w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
            type="button" onClick={() => {history.push("/user/language")}}>
            {t("Cambiar idioma")}
          </button>
          <button
            className={"w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
            type="button" onClick={() => {window.open("https://www.pamesa.com/PROTECCIONDATOS.html?pd=contact", "_blank", "noreferrer")}}>
            {t("Condiciones y política de privacidad")}
          </button>
          <button
            className={"w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
            type="button" onClick={() => setLogOutPopup(true)}>
            {t("Cerrar sesión")}
          </button>
        </div>
      </div>
      <ConfirmDialog
        title="Cerrar sesion"
        open={logOutPopup}
        onClose={() => setLogOutPopup(false)}
        onConfirm={handleLogOut}
      >
        {t("¿Está seguro de cerrar la sesión?")}
      </ConfirmDialog>
    </div>
  );
}

export default SettingsPage;