import React from "react";
import {Link, useHistory} from "react-router-dom";
import Header from "../../components/header";

const AdminPage = () => {

  const history = useHistory()

  return (
    <div className={"container mx-auto p-4"}>
      <Header onBack={history.goBack} />
      <div className={"flex justify-center my-5"}>
        <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center lowercase">Administración</h2>
      </div>
      <div className={"flex flex-col justify-center items-center my-5"}>
        <Link to={"/admin/visitas"} className={"px-4 py-3 my-5 rounded-md bg-neutral-400 text-white text-sm font-bold shadow-drop-bottom uppercase text-center"}>
          Visitas
        </Link>
        <Link to={"/admin/comerciales"} className={"px-4 py-3 my-5 rounded-md bg-neutral-400 text-white text-sm font-bold shadow-drop-bottom uppercase"}>
          Comerciales
        </Link>
        <Link to={"/admin/productos"} className={"px-4 py-3 my-5 rounded-md bg-neutral-400 text-white text-sm font-bold shadow-drop-bottom uppercase"}>
          Productos
        </Link>
      </div>
    </div>
  )
}

export default AdminPage;