import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import { HiTrash } from "react-icons/hi";
import moment from "moment"
import "moment/locale/es"
import Header from "../../components/header";
import ConfirmDialog from "../../components/confirm-dialog";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const AdminVisitasPage = ({obtenerAdminVisitas, eliminarAdminVisitas}) => {
  moment.locale("es")

  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [visitasData, setVisitasData] = useState([])
  const [visitasColumns, setVisitasColumns] = useState([])
  const [visitaEliminar, setVisitaEliminar] = useState(null)

  useEffect(() => {
    const columns = [
      {
        name: "Comercial",
        selector: row => row.comerciale.username,
        sortable: true
      },
      {
        name: "Cliente",
        selector: row => row.cliente,
        sortable: true
      },
      {
        name: "Email",
        selector: row => row.correo,
        sortable: true
      },
      {
        name: "Fecha",
        selector: row => moment(row.fecha_creacion).format("L LTS"),
        sortable: true,
        sortFunction: (a, b) => a.fecha_creacion > b.fecha_creacion ? 1 : -1
      },
      {
        cell: (data) => <button onClick={() => handleRemoveClick(data)}><HiTrash/></button>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];
    setVisitasColumns(columns)
  }, []);

  useEffect(() => {
    setLoading(true)
    obtenerAdminVisitas()
      .then((visitas) => {
        setVisitasData(visitas.map((v) => {
          v.items = parseVisitaItems(v)
          return v
        }))
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [obtenerAdminVisitas])

  const parseVisitaItems = (visita) => {
    return [...visita.productos, ...visita.series]
      .sort((a,b) => a.createdAt < b.cretedAt ? 1 : -1)
      .map(item => ({
        ...item,
        discriminator: item.nombre ? "serie" : "producto",
        extra: item.nombre ? item.visita_series : item.visita_productos
      }))
  }

  const handleRemoveClick = (data) => {
    console.log(data);
    setVisitaEliminar(data);
    setConfirmOpen(true);
  }

  const handleDelete = () => {
    setConfirmOpen(false);
    setLoading(true)
    eliminarAdminVisitas(visitaEliminar.id)
      .then(() => {
        toast.success("La visita se ha eliminado con éxito")
        obtenerAdminVisitas()
          .then((visitas) => {
            setVisitasData(visitas.map((v) => {
              v.items = parseVisitaItems(v)
              return v
            }))
            setLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al eliminar la visita")
        setLoading(false)
      })
    setVisitaEliminar(null);
  }

  const ExpandedVisitaComponent = ({ data }) => {
    return data.items.length > 0 ?
      data.items.map((product) => (
        <div className={"grid grid-cols-1 sm:grid-cols-8 py-5 px-2"} key={product.id}>
          {product.discriminator === "producto" &&
          <div
            className={
              "col-span-1 sm:col-span-2 px-4 flex flex-col justify-center items-center my-2 sm:my-0"
            }
          >
            <img
              className=" object-cover rounded-md shadow-drop-bottom h-14 md:h-14 w-full"
              src={`${BASE_URL}/piezas/${product.imagen}.jpg`}
              alt={product.color}
            />
          </div>
          }
          <div
            className={
              `${product.discriminator === "producto" ? "sm:col-span-3" : "sm:col-span-8"} col-span-1 px-4 flex flex-col justify-center my-2 sm:my-0`
            }
          >
            <div className={"flex flex-col justify-center my-1"}>
              <span
                className={
                  "text-sm font-medium leading-tight text-center text-gray-800 uppercase cursor-default"
                }
              >
                {product.discriminator === "producto" && `${product.serie} - ${product.color}`}
                {product.discriminator === "serie" && `${product.nombre} - completa`}
              </span>
            </div>
          </div>
          {product.discriminator === "producto" &&
          <>
            <div
              className={
                "col-span-1 sm:col-span-1 px-4 flex flex-col justify-center my-2 sm:my-0"
              }
            >
              <div className={"flex flex-row justify-center my-1"}>
                <span
                  className={
                    "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-md text-gray-800 text-sm uppercase cursor-default whitespace-nowrap text-center"
                  }
                >
                  {product.formato}
                </span>
              </div>
            </div>
            <div
              className={
                "col-span-1 sm:col-span-2 px-4 flex flex-col justify-center my-2 sm:my-0"
              }
            >
              <div className={"flex flex-row justify-center my-1"}>
                <span
                  className={
                    "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-md text-gray-800 text-sm uppercase cursor-default whitespace-nowrap text-center"
                  }
                >
                  {product.acabado}
                </span>
              </div>
            </div>
          </>
          }
          <div
            className={
              "col-span-1 sm:col-span-4 px-4 flex flex-col justify-center my-2 sm:my-0"
            }
          >
            <div className={"w-fit pt-2 pb-3 px-2"}>
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-xs text-left text-gray-800 cursor-default"}>Camiones:</span>
                <span
                  className={"text-xs text-right text-gray-800 cursor-default"}>{product.extra.camiones || "-"}</span>
              </div>
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-xs text-left text-gray-800 cursor-default"}>Palets:</span>
                <span
                  className={"text-xs text-right text-gray-800 cursor-default"}>{product.extra.palets || "-"}</span>
              </div>
              <div className={"grid grid-cols-2 py-2 px-5"}>
                <span
                  className={"w-fit font-medium text-xs text-left text-gray-800 cursor-default"}>Metros&#178;:</span>
                <span
                  className={"text-xs text-right text-gray-800 cursor-default"}>{product.extra.metros2 || "-"}</span>
              </div>
            </div>
          </div>
          <div
            className={
              "col-span-1 sm:col-span-4 px-4 flex flex-col justify-center my-2 sm:my-0"
            }
          >
            <span className={"text-xs font-medium text-center text-gray-800 cursor-default mb-2"}>Promociones</span>
            <span
              className={"w-max-prose text-xs text-center text-gray-800 cursor-default"}>{product.extra.promociones || "-"}</span>
          </div>
        </div>
      )) :
      (
        <div className={"flex flex-row justify-center my-1 py-4"}>
          <span
            className={
              "text-sm font-medium leading-tight text-center text-gray-800 cursor-default"
            }
          >
            La lista está vacía.
          </span>
        </div>
      )
  };

  return (
    <>
      <Toaster toastOptions={{
        success: {
          iconTheme: {
            primary: "black",
            secondary: "white",
          },
        },
      }}/>
      <div className={"container mx-auto p-4"}>
        <Header onBack={history.goBack} />
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center lowercase">visitas</h2>
        </div>
        <div className={"container mx-auto p-4 max-w-screen-lg"}>
          <DataTable
            columns={visitasColumns}
            data={visitasData}
            pagination
            progressPending={loading}
            expandOnRowClicked
            expandableRows
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedVisitaComponent}
          />
          <ConfirmDialog
            title="Eliminar visita"
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            onConfirm={handleDelete}
          >
            {visitaEliminar &&
              <>
                <span>¿Está seguro de eliminar la visita del cliente </span>
                <span className={"font-bold"}>{visitaEliminar.cliente}</span>
                <span> realizada por </span>
                <span className={"font-bold"}>{visitaEliminar.comerciale.username}</span>
                <span>a fecha </span>
                <span className={"font-bold"}>{moment(visitaEliminar.fecha_creacion).format("L LTS")}</span>
                <span>?</span>
              </>
            }
          </ConfirmDialog>
        </div>
      </div>
    </>
  )
}

export default AdminVisitasPage;