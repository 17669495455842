const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const obtenerComercial = (comercialUsername) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const obtenerVisitas = (comercialUsername) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}/visita`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const crearVisita = (comercialUsername, visitaData) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/comercial/${comercialUsername}/visita`, {
      method: "POST",
      body: JSON.stringify(visitaData),
      headers: { "Content-Type": "application/json","Accept":"application/json" },
    })
      .then(r => r.json())
      .then(response => resolve(response.id))
      .catch(reject)
  })
}

export const finalizarVisita = (visitaId, language) => {
  return new Promise((resolve, reject) => {
    const url = language ? `${API_BASE_URL}/api/visita/${visitaId}?lang=${language}`
      : `${API_BASE_URL}/api/visita/${visitaId}`
    fetch(url, {
      method: "PATCH"
    })
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const obtenerVisita = (visitaId) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/visita/${visitaId}`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const obtenerProductos = (serie) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/producto/${serie}`)
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const anadirProducto = (visitaId, productoId, materialesPromocionesData) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/visita/${visitaId}/producto/${productoId}`, {
      method: "PATCH",
      body: JSON.stringify(materialesPromocionesData),
      headers: { "Content-Type": "application/json","Accept":"application/json" }
    })
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const anadirSerie = (visitaId, serie, materialesPromocionesData) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/visita/${visitaId}/serie/${serie}`, {
      method: "PATCH",
      body: JSON.stringify(materialesPromocionesData),
      headers: {"Content-Type": "application/json", "Accept": "application/json"}
    })
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const eliminarProducto = (visitaId, productoId) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/visita/${visitaId}/producto/${productoId}`, {
      method: "DELETE"
    })
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const eliminarSerie = (visitaId, serie) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/visita/${visitaId}/serie/${serie}`, {
      method: "DELETE"
    })
      .then(r => r.json())
      .then(response => resolve(response))
      .catch(reject)
  })
}

export const crearAnonComercial = () => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/comercial/anon`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: { "Content-Type": "application/json","Accept":"application/json" },
    })
      .then(r => r.json())
      .then(response => resolve(response.username))
      .catch(reject)
  })
}

export const resendVisita = (visitaId, language) => {
  return new Promise((resolve, reject) => {
    const url = language ? `${API_BASE_URL}/api/visita/${visitaId}/resend?lang=${language}`
      : `${API_BASE_URL}/api/visita/${visitaId}/resend`
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json","Accept":"application/json" },
    })
      .then(r => r.json())
      .then(response => resolve(response.username))
      .catch(reject)
  })
}

export const registroCliente = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_BASE_URL}/api/cliente`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json","Accept":"application/json" },
    })
      .then(r => r.json())
      .then(response => {
        if (response.user) {
          const user = {
            token: true,
            dataValues: response.user
          }
          localStorage.setItem("user", JSON.stringify(user));
        }
        if (response.visita) {
          resolve(response.visita.id)
        } else {
          reject()
        }
      })
      .catch(reject)
  })
}