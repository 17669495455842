import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const ScanSeriePage = ({ anadirSerie }) => {
  const history = useHistory()
  const { serie } = useParams()

  useEffect(() => {
    const visitaId = localStorage.getItem("tau-pamesa-current-visita")
    if (!!visitaId && visitaId != -1) {
      const materialesPromocionesData = {
        camiones: 0,
        palets: 0,
        metros2: 0,
        promociones: ""
      }
      anadirSerie(visitaId, serie, materialesPromocionesData)
        .then(cierraPestana)
        .catch(console.error)
    }
  }, [])

  const cierraPestana = () => {
    const visitaId = localStorage.getItem("tau-pamesa-current-visita")
    history.push(`/visita/${visitaId}`)
  }
}

export default ScanSeriePage;