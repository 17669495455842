import React from "react"

import Header from "./header"

const Layout = ({ shouldNotDisplayBackButton, children }) => {
  return (
    <div className={"container mx-auto p-4 max-h-screen"}>
      <Header shouldNotDisplayBackButton={shouldNotDisplayBackButton} />
      {children}
    </div>
  )
}

export default Layout
