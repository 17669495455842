import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InfoDialog from "../../components/info-dialog";

function ProfilePage() {
  const [displayConfirmPopup, setDisplayConfirmPopup] = useState(false)
  const [formData, setFormData] = useState({
    cliente: "",
    correo: "",
    telefono: "",
    empresa: "",
    pais: "",
    provincia: "",
    sector: ""
  })

  const [formErrors, setFormErrors] = useState({
    cliente: null,
    correo: null,
    telefono: null,
    empresa: null,
    pais: null,
    provincia: null,
    sector: null
  })

  useEffect(() => {
    const rawProfile = localStorage.getItem("pamesa-wishlist-user-profile")
    if (!rawProfile) return
    const profile = JSON.parse(rawProfile)
    if (profile) {
      setFormData(profile)
    }
  }, [])

  const history = useHistory()
  const { t } = useTranslation()

  const handleNewList = () => {
    let isErrored = false
    let _formErrors = {
      cliente: null,
      correo: null,
      telefono: null,
      empresa: null,
      pais: null,
      provincia: null,
      sector: null
    }

    if (!formData.cliente || formData.cliente.length < 1) {
      _formErrors.cliente = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    if (!formData.correo || formData.correo.length < 1) {
      _formErrors.correo = {
        message: "Debe informar el campo"
      }
      isErrored = true
      //eslint-disable-next-line
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.correo))) {
      _formErrors.correo = {
        message: "La dirección no es correcta"
      }
      isErrored = true
    }

    if (!formData.telefono || formData.telefono.length < 8) {
      _formErrors.telefono = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    if (!formData.pais || formData.pais.length < 1) {
      _formErrors.pais = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    if (!formData.sector || formData.sector.length < 1) {
      _formErrors.sector = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    setFormErrors(_formErrors)
    if (!isErrored) {
      localStorage.setItem("pamesa-wishlist-user-profile", JSON.stringify(formData))
      setDisplayConfirmPopup(true)
    }
  }

  return (
    <div className={"container mx-auto p-4 max-w-screen-sm"}>
      <div className={"grid grid-cols-1 mb-5"}>
        <div className={"flex flex-column justify-center items-center"}>
          <h2 className="w-5/6 text-2xl font-medium text-black text-center">{t("Cuenta")}</h2>
        </div>
      </div>
      <div className={"grid grid-cols-1 mb-3"}>
        <div className={"flex flex-column justify-center items-center"}>
          <form className={"w-5/6 "}>
            <div className="relative mb-3">
              <input id="cliente" type="text"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["cliente"]}
                onChange={e => setFormData({...formData, cliente: e.target.value})}/>
              <label htmlFor="cliente"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Nombre")}</label>
            </div>
            {formErrors.cliente && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.cliente.message)}
              </div>
            )}

            <div className="relative mb-3">
              <input id="correo-electronico" type="email"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                disabled={true}
                value={formData["correo"]}
                onChange={e => setFormData({...formData, "correo": e.target.value})}/>
              <label htmlFor="correo-electronico"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("E-mail")}</label>
            </div>
            {formErrors.correo && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.correo.message)}
              </div>
            )}

            <div className="relative mb-3">
              <input id="telefono" type="tel"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["telefono"]}
                onChange={e => setFormData({...formData, "telefono": e.target.value})}/>
              <label htmlFor="telefono"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Teléfono")}</label>
            </div>
            {formErrors.telefono && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.telefono.message)}
              </div>
            )}

            <div className="relative mb-3">
              <input id="empresa" type="text"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["empresa"]}
                onChange={e => setFormData({...formData, "empresa": e.target.value})}/>
              <label htmlFor="empresa"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Empresa")}</label>
            </div>
            {formErrors.empresa && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.empresa.message)}
              </div>
            )}

            <div className="relative mb-3">
              <select id="pais"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["pais"]}
                onChange={e => setFormData({...formData, "pais": e.target.value})}>
                <option selected></option>
                <option value={"ÁFRICA"}>ÁFRICA</option>
                <option value={"ALBANIA"}>ALBANIA</option>
                <option value={"ALEMANIA"}>ALEMANIA</option>
                <option value={"ANGOLA"}>ANGOLA</option>
                <option value={"ANTIGUA - CARIBE"}>ANTIGUA - CARIBE</option>
                <option value={"ANTILLAS HOLANDESAS"}>ANTILLAS HOLANDESAS</option>
                <option value={"ARABIA SAUDITA"}>ARABIA SAUDITA</option>
                <option value={"ARGELIA "}>ARGELIA </option>
                <option value={"ARGENTINA"}>ARGENTINA</option>
                <option value={"ARMENIA"}>ARMENIA</option>
                <option value={"ASIA ORIENTAL"}>ASIA ORIENTAL</option>
                <option value={"AUSTRALIA"}>AUSTRALIA</option>
                <option value={"AUSTRIA"}>AUSTRIA</option>
                <option value={"AZERBAYAN"}>AZERBAYAN</option>
                <option value={"BAHAMAS"}>BAHAMAS</option>
                <option value={"BAHREIN"}>BAHREIN</option>
                <option value={"BANGLADESH"}>BANGLADESH</option>
                <option value={"BARBADOS"}>BARBADOS</option>
                <option value={"BELGICA"}>BELGICA</option>
                <option value={"BERMUDAS"}>BERMUDAS</option>
                <option value={"BIELORUSIA"}>BIELORUSIA</option>
                <option value={"BOLIVIA"}>BOLIVIA</option>
                <option value={"BOSNIA"}>BOSNIA</option>
                <option value={"BOTSWANA"}>BOTSWANA</option>
                <option value={"BRASIL"}>BRASIL</option>
                <option value={"BRUNEI"}>BRUNEI</option>
                <option value={"BULGARIA"}>BULGARIA</option>
                <option value={"BURKINA FASO"}>BURKINA FASO</option>
                <option value={"CABO VERDE"}>CABO VERDE</option>
                <option value={"CAMBOYA"}>CAMBOYA</option>
                <option value={"CAMERUN"}>CAMERUN</option>
                <option value={"CANADA"}>CANADA</option>
                <option value={"CHILE"}>CHILE</option>
                <option value={"CHINA"}>CHINA</option>
                <option value={"CHIPRE"}>CHIPRE</option>
                <option value={"COLOMBIA"}>COLOMBIA</option>
                <option value={"CONGO REP DEM"}>CONGO REP DEM</option>
                <option value={"COREA DEL SUR"}>COREA DEL SUR</option>
                <option value={"COSTA DE MARFIL"}>COSTA DE MARFIL</option>
                <option value={"COSTA RICA"}>COSTA RICA</option>
                <option value={"CRETA"}>CRETA</option>
                <option value={"CROACIA"}>CROACIA</option>
                <option value={"CUBA"}>CUBA</option>
                <option value={"DINAMARCA"}>DINAMARCA</option>
                <option value={"ECUADOR"}>ECUADOR</option>
                <option value={"EEUU"}>EEUU</option>
                <option value={"EGIPTO"}>EGIPTO</option>
                <option value={"EL SALVADOR"}>EL SALVADOR</option>
                <option value={"EMIRATOS ARABES"}>EMIRATOS ARABES</option>
                <option value={"ESCANDINAVIA . P NORDICOS"}>ESCANDINAVIA . P NORDICOS</option>
                <option value={"ESCOCIA"}>ESCOCIA</option>
                <option value={"ESLOVAQUIA"}>ESLOVAQUIA</option>
                <option value={"ESLOVENIA"}>ESLOVENIA</option>
                <option value={"ESPAÑA"}>ESPAÑA</option>
                <option value={"ESTONIA"}>ESTONIA</option>
                <option value={"ETIOPIA"}>ETIOPIA</option>
                <option value={"FILIPINAS"}>FILIPINAS</option>
                <option value={"FINLANDIA"}>FINLANDIA</option>
                <option value={"FRANCIA"}>FRANCIA</option>
                <option value={"GALES"}>GALES</option>
                <option value={"GAMBIA"}>GAMBIA</option>
                <option value={"GEORGIA"}>GEORGIA</option>
                <option value={"GHANA"}>GHANA</option>
                <option value={"GRANADA - ISLA CARIBE"}>GRANADA - ISLA CARIBE</option>
                <option value={"GRECIA"}>GRECIA</option>
                <option value={"GUADALUPE"}>GUADALUPE</option>
                <option value={"GUATEMALA"}>GUATEMALA</option>
                <option value={"GUAYANA"}>GUAYANA</option>
                <option value={"GUINEA ECUATORIAL"}>GUINEA ECUATORIAL</option>
                <option value={"GUYANA Y GUYANA FRANCESA"}>GUYANA Y GUYANA FRANCESA</option>
                <option value={"HAITI"}>HAITI</option>
                <option value={"HAWAII"}>HAWAII</option>
                <option value={"HOLANDA. P. BAJOS"}>HOLANDA. P. BAJOS</option>
                <option value={"HONDURAS"}>HONDURAS</option>
                <option value={"HONG KONG"}>HONG KONG</option>
                <option value={"HUNGRIA"}>HUNGRIA</option>
                <option value={"INDIA"}>INDIA</option>
                <option value={"INGLATERRA"}>INGLATERRA</option>
                <option value={"IRAN"}>IRAN</option>
                <option value={"IRAQ"}>IRAQ</option>
                <option value={"IRLANDA"}>IRLANDA</option>
                <option value={"IRLANDA DEL NORTE"}>IRLANDA DEL NORTE</option>
                <option value={"ISLANDIA"}>ISLANDIA</option>
                <option value={"ISLAS CAIMAN"}>ISLAS CAIMAN</option>
                <option value={"ISLAS MAURICIO"}>ISLAS MAURICIO</option>
                <option value={"ISLAS REUNION"}>ISLAS REUNION</option>
                <option value={"ISRAEL – ARABE"}>ISRAEL – ARABE</option>
                <option value={"ISRAEL – JUDIO"}>ISRAEL – JUDIO</option>
                <option value={"ITALIA"}>ITALIA</option>
                <option value={"JAMAICA"}>JAMAICA</option>
                <option value={"JAPON"}>JAPON</option>
                <option value={"JORDANIA"}>JORDANIA</option>
                <option value={"KAZAJISTAN"}>KAZAJISTAN</option>
                <option value={"KENIA"}>KENIA</option>
                <option value={"KIRGUISTAN"}>KIRGUISTAN</option>
                <option value={"KOREA"}>KOREA</option>
                <option value={"KOSOVO"}>KOSOVO</option>
                <option value={"KURGISTAN"}>KURGISTAN</option>
                <option value={"KUWAIT"}>KUWAIT</option>
                <option value={"LETONIA / LATVIA"}>LETONIA / LATVIA</option>
                <option value={"LIBANO"}>LIBANO</option>
                <option value={"LIBIA"}>LIBIA</option>
                <option value={"LITUANIA"}>LITUANIA</option>
                <option value={"LUXEMBURGO"}>LUXEMBURGO</option>
                <option value={"MACEDONIA"}>MACEDONIA</option>
                <option value={"MADAGASCAR"}>MADAGASCAR</option>
                <option value={"MALASIA"}>MALASIA</option>
                <option value={"MALAWI"}>MALAWI</option>
                <option value={"MALI"}>MALI</option>
                <option value={"MALTA"}>MALTA</option>
                <option value={"MARRUECOS"}>MARRUECOS</option>
                <option value={"MARTINICA"}>MARTINICA</option>
                <option value={"MAURICIO"}>MAURICIO</option>
                <option value={"MAURITANIA"}>MAURITANIA</option>
                <option value={"MAYOTTE"}>MAYOTTE</option>
                <option value={"MEXICO"}>MEXICO</option>
                <option value={"MOLDAVIA"}>MOLDAVIA</option>
                <option value={"MONGOLIA"}>MONGOLIA</option>
                <option value={"MONTENEGRO"}>MONTENEGRO</option>
                <option value={"MOZAMBIQUE"}>MOZAMBIQUE</option>
                <option value={"NAMIBIA"}>NAMIBIA</option>
                <option value={"NICARAGUA"}>NICARAGUA</option>
                <option value={"NÍGER/NIGERIA"}>NÍGER/NIGERIA</option>
                <option value={"NORUEGA"}>NORUEGA</option>
                <option value={"NUEVA  ZELANDA"}>NUEVA  ZELANDA</option>
                <option value={"NUEVA CALEDONIA"}>NUEVA CALEDONIA</option>
                <option value={"OMAN"}>OMAN</option>
                <option value={"PAISES BAJOS  - HOLANDA"}>PAISES BAJOS  - HOLANDA</option>
                <option value={"PAKISTAN"}>PAKISTAN</option>
                <option value={"PALESTINA"}>PALESTINA</option>
                <option value={"PANAMA"}>PANAMA</option>
                <option value={"PARAGUAY"}>PARAGUAY</option>
                <option value={"PERU"}>PERU</option>
                <option value={"POLONIA"}>POLONIA</option>
                <option value={"PORTUGAL"}>PORTUGAL</option>
                <option value={"PUERTO RICO"}>PUERTO RICO</option>
                <option value={"QATAR"}>QATAR</option>
                <option value={"REINO UNIDO"}>REINO UNIDO</option>
                <option value={"REPUBLICA CHECA"}>REPUBLICA CHECA</option>
                <option value={"RUANDA"}>RUANDA</option>
                <option value={"RUMANIA"}>RUMANIA</option>
                <option value={"RUSIA"}>RUSIA</option>
                <option value={"SAINT VINCENT - CARIBE"}>SAINT VINCENT - CARIBE</option>
                <option value={"SANTA LUCIA"}>SANTA LUCIA</option>
                <option value={"SENEGAL"}>SENEGAL</option>
                <option value={"SERBIA"}>SERBIA</option>
                <option value={"SINGAPUR"}>SINGAPUR</option>
                <option value={"SIRIA "}>SIRIA </option>
                <option value={"SOMALIA"}>SOMALIA</option>
                <option value={"SUDAFRICA"}>SUDAFRICA</option>
                <option value={"SUDAMERICA"}>SUDAMERICA</option>
                <option value={"SUDAN"}>SUDAN</option>
                <option value={"SUECIA"}>SUECIA</option>
                <option value={"SUIZA"}>SUIZA</option>
                <option value={"TADJIKISTAN"}>TADJIKISTAN</option>
                <option value={"TAHITI"}>TAHITI</option>
                <option value={"TAILANDIA"}>TAILANDIA</option>
                <option value={"TAIWAN"}>TAIWAN</option>
                <option value={"TANZANIA"}>TANZANIA</option>
                <option value={"TOGO"}>TOGO</option>
                <option value={"TRINIDAD Y TOBAGO"}>TRINIDAD Y TOBAGO</option>
                <option value={"TUNEZ"}>TUNEZ</option>
                <option value={"TURCOS & CAICOS - CARIBE"}>TURCOS & CAICOS - CARIBE</option>
                <option value={"TURKMENISTAN"}>TURKMENISTAN</option>
                <option value={"TURQUIA"}>TURQUIA</option>
                <option value={"UCRANIA"}>UCRANIA</option>
                <option value={"UGANDA"}>UGANDA</option>
                <option value={"URUGUAY"}>URUGUAY</option>
                <option value={"UZBEKISTAN"}>UZBEKISTAN</option>
                <option value={"VENEZUELA"}>VENEZUELA</option>
                <option value={"VIETNAM"}>VIETNAM</option>
                <option value={"YEMEN"}>YEMEN</option>
                <option value={"ZIMBABWE"}>ZIMBABWE</option>
              </select>
              <label htmlFor="pais"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("País")}</label>
            </div>
            {formErrors.pais && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.pais.message)}
              </div>
            )}

            <div className="relative mb-3">
              <select id="provincia"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["provincia"]}
                onChange={e => setFormData({...formData, "provincia": e.target.value})}>
                <option selected></option>
                {formData.pais !== "ESPAÑA" && (
                  <option value={"N/A"}>N/A</option>
                )}
                {formData.pais === "ESPAÑA" && (
                  <>
                    <option value={"ALAVA -VITORIA"}>ALAVA -VITORIA</option>
                    <option value={"ALBACETE"}>ALBACETE</option>
                    <option value={"ALICANTE"}>ALICANTE</option>
                    <option value={"ALMERÍA"}>ALMERÍA</option>
                    <option value={"ANDORRA"}>ANDORRA</option>
                    <option value={"ASTURIAS - OVIEDO"}>ASTURIAS - OVIEDO</option>
                    <option value={"ÁVILA"}>ÁVILA</option>
                    <option value={"BADAJOZ"}>BADAJOZ</option>
                    <option value={"BALEARES"}>BALEARES</option>
                    <option value={"BARCELONA"}>BARCELONA</option>
                    <option value={"BURGOS"}>BURGOS</option>
                    <option value={"CÁCERES"}>CÁCERES</option>
                    <option value={"CÁDIZ"}>CÁDIZ</option>
                    <option value={"CANARIAS"}>CANARIAS</option>
                    <option value={"CANTABRIA -  SANTANDER"}>CANTABRIA -  SANTANDER</option>
                    <option value={"CASTELLÓN"}>CASTELLÓN</option>
                    <option value={"CEUTA"}>CEUTA</option>
                    <option value={"CIUDAD REAL"}>CIUDAD REAL</option>
                    <option value={"CÓRDOBA"}>CÓRDOBA</option>
                    <option value={"CUENCA"}>CUENCA</option>
                    <option value={"GERONA"}>GERONA</option>
                    <option value={"GRANADA"}>GRANADA</option>
                    <option value={"GUADALAJARA"}>GUADALAJARA</option>
                    <option value={"GUIPÚZCOA - SAN SEBASTIÁN"}>GUIPÚZCOA - SAN SEBASTIÁN</option>
                    <option value={"HUELVA"}>HUELVA</option>
                    <option value={"HUESCA"}>HUESCA</option>
                    <option value={"JAÉN"}>JAÉN</option>
                    <option value={"LA CORUÑA"}>LA CORUÑA</option>
                    <option value={"LA RIOJA -  LOGROÑO"}>LA RIOJA -  LOGROÑO</option>
                    <option value={"LEÓN"}>LEÓN</option>
                    <option value={"LERIDA"}>LERIDA</option>
                    <option value={"LUGO"}>LUGO</option>
                    <option value={"MADRID"}>MADRID</option>
                    <option value={"MÁLAGA"}>MÁLAGA</option>
                    <option value={"MELILLA"}>MELILLA</option>
                    <option value={"MURCIA"}>MURCIA</option>
                    <option value={"NAVARRA -  PAMPLONA"}>NAVARRA -  PAMPLONA</option>
                    <option value={"ORENSE"}>ORENSE</option>
                    <option value={"PALENCIA"}>PALENCIA</option>
                    <option value={"PONTEVEDRA"}>PONTEVEDRA</option>
                    <option value={"PORTUGAL – AZ. EL VADO"}>PORTUGAL – AZ. EL VADO</option>
                    <option value={"SALAMANCA"}>SALAMANCA</option>
                    <option value={"SEGOVIA"}>SEGOVIA</option>
                    <option value={"SEVILLA"}>SEVILLA</option>
                    <option value={"SORIA"}>SORIA</option>
                    <option value={"TARRAGONA"}>TARRAGONA</option>
                    <option value={"TERUEL"}>TERUEL</option>
                    <option value={"TOLEDO"}>TOLEDO</option>
                    <option value={"VALENCIA"}>VALENCIA</option>
                    <option value={"VALLADOLID"}>VALLADOLID</option>
                    <option value={"VIZCAYA - BILBAO"}>VIZCAYA - BILBAO</option>
                    <option value={"ZAMORA"}>ZAMORA</option>
                    <option value={"ZARAGOZA "}>ZARAGOZA </option>
                    <option value={"ZARAGOZA GENERAL"}>ZARAGOZA GENERAL</option>
                  </>
                )}
              </select>
              <label htmlFor="provincia"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Región")}</label>
            </div>
            {formErrors.provincia && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.provincia.message)}
              </div>
            )}

            <div className="relative mb-3">
              <select id="sector"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={formData["sector"]}
                onChange={e => setFormData({...formData, "sector": e.target.value})}>
                <option selected></option>
                <option value={"particular"}>Particular</option>
                <option value={"profesional"}>Profesional</option>
                <option value={"arquitecto"}>Arquitecto</option>
                <option value={"interiorista"}>Interiorista</option>
              </select>
              <label htmlFor="sector"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Sector")}</label>
            </div>
            {formErrors.sector && (
              <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                {t(formErrors.sector.message)}
              </div>
            )}

            <div className={"w-full flex flex-row justify-center items-center"}>
              <button
                className={"w-full mt-3 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
                type="button" onClick={handleNewList}>
                {t("Guardar")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <InfoDialog
        title={""}
        open={displayConfirmPopup}
        onClose={() => {history.goBack()}}
      >
        <p>{t("Los datos han sido actualizados.")}</p>
      </InfoDialog>
    </div>
  )
}

export default ProfilePage;