import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { HiOutlineInformationCircle, HiOutlinePencil } from "react-icons/hi";
import InfoDialog from "../../components/info-dialog";
import {useTranslation} from "react-i18next";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const SelectSerieProductsPage = ({ obtenerProductos, anadirProducto, obtenerVisita }) => {
  const [productos, setProductos] = useState([])
  const [colores, setColores] = useState([])
  const [additionalInfoOpen, setAdditionalInfoOpen] = useState(false)
  const [extraInfoOpen, setExtraInfoOpen] = useState(false)
  const [finishPopupOpen, setFinishPopupOpen] = useState(false)

  const [productosSeleccionados, setProductosSeleccionados] = useState([])
  const [productIdExtraData, setProductIdExtraData] = useState(null)
  const [productExtraData, setProductExtraData] = useState({})
  const [extraData, setExtraData] = useState({
    metros2: null,
    palets: null,
    camiones: null,
    promocion: null,
  })

  const { visitaId, serie } = useParams()
  const history = useHistory();
  const { t } = useTranslation()

  useEffect(() => {
    obtenerProductos(serie)
      .then((productos) => {
        setProductos(productos)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [obtenerProductos, serie])

  useEffect(() => {
    const colors = productos.map(p => ({
      color: p.color,
      imagen: p.imagen
    }))
    const presentColors = []
    setColores(colors.filter((c) => {
      if (presentColors.indexOf(c.color) === -1) {
        presentColors.push(c.color)
        return true;
      }
      return false
    }))
  }, [productos])

  useEffect(() => {
    obtenerVisita(visitaId)
      .then((visita) => {
        const _productosSeleccionados = [...productosSeleccionados]
        const _productExtraData = {...productExtraData}
        visita.productos.filter(p => p.serie === serie)
          .forEach((p) => {
            _productosSeleccionados.push(p.id)
            _productExtraData[p.id] = p.visita_productos
          })
        setProductosSeleccionados(_productosSeleccionados)
        setProductExtraData(_productExtraData)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [visitaId, obtenerVisita])

  const handleProductSelection = (productId) => {
    const indexOf = productosSeleccionados.indexOf(productId)
    if (indexOf > -1) {
      const _productosSeleccionados = [...productosSeleccionados]
      _productosSeleccionados.splice(indexOf, 1)
      setProductosSeleccionados(_productosSeleccionados)
    } else {
      setProductosSeleccionados([...productosSeleccionados, productId])
    }
  }

  const handleExtraDataOpen = (productId) => {
    setProductIdExtraData(productId)
    if (productExtraData[productId]) {
      setExtraData(productExtraData[productId])
    }
    setExtraInfoOpen(true)
  }

  const handleExtraDataClose = () => {
    if (productIdExtraData !== null) {
      setProductExtraData({
        ...productExtraData,
        [productIdExtraData]: extraData
      })
    }

    setProductIdExtraData(null)
    setExtraInfoOpen(false)
    setExtraData({
      metros2: null,
      palets: null,
      camiones: null,
      promocion: null,
    })
  }

  const handleAddProduct = () => {
    const productIds = [...productosSeleccionados.values()]
    if (productIds.length > 0) {
      Promise.all(productIds.map(pId => {
        const extraData = productExtraData[pId] ? productExtraData[pId] : {
          metros2: null,
          palets: null,
          camiones: null,
          promocion: null,
        }
        return anadirProducto(visitaId, pId, extraData, serie)
      }))
        .then(() => setFinishPopupOpen(true))
        .catch(console.error)
    }
  }

  const handleFinish = () => {
    setFinishPopupOpen(false)
    history.replace(`/visita/${visitaId}`)
  }

  return (
    <div className={"container mx-auto p-4 max-w-screen-md h-[85vh]"}>
      <div
        className={"flex justify-center mb-3"}>
        <span className={"my-3 mt-2 text-center text-2xl font-bold uppercase words chars splitting"}>{serie}</span>
        <div role={"button"} tabIndex={-1}
          className={
            "w-12 h-12 rounded-md flex justify-center items-start"
          }
          onClick={() => setAdditionalInfoOpen(true)}
          onKeyDown={() => setAdditionalInfoOpen(true)}
        >
          <HiOutlineInformationCircle className={"text-lg"} />
        </div>
      </div>
      <div className={"h-[75%] overflow-y-scroll no-scrollbar px-2"}>
        {colores.map(color => (
          <div className={"mb-10"} key={color.color}>
            <h2 className={"font-bold pl-1 mb-1"}>{color.color}</h2>
            <img className={"h-32 md:h-20 w-full object-cover rounded-md shadow-drop-bottom mb-2"}
              src={`${BASE_URL}/piezas/${color.imagen}.jpg`} alt={color.color}/>
            {productos.filter(p => p.color === color.color).map(p => (
              <button type="button" key={p.id}
                className={`w-full px-2 rounded-md text-sm text-left font-bold uppercase ${productosSeleccionados.indexOf(p.id) > -1 ? "bg-redPamesa text-white" : "bg-zinc-400 text-gray-300"}`}
                onClick={() => handleProductSelection(p.id)}
              >
                <div className={"flex flex-row justify-between items-center"}>
                  <span>{p.formato} | {p.acabado}</span>
                  {productosSeleccionados.indexOf(p.id) > -1 && (
                    <div role={"button"} tabIndex={-1}
                      onClick={(e) => {e.stopPropagation(); handleExtraDataOpen(p.id)}}
                      onKeyDown={(e) => {e.stopPropagation(); handleExtraDataOpen(p.id)}}
                    >
                      <HiOutlinePencil className={"text-md"}/>
                    </div>
                  )}
                </div>
              </button>
            ))}
          </div>
        ))}
      </div>
      <div className={"w-full flex flex-row justify-center items-center my-3 pt-3"}>
        <button
          className={`w-3/5 mt-3 px-4 py-3 rounded-md text-white text-sm font-bold shadow-drop-bottom uppercase ${productosSeleccionados.length > 0 ? "bg-redPamesa" : "bg-zinc-400"}`}
          type="button" onClick={handleAddProduct}>
          {t("Añadir")}
        </button>
      </div>
      <InfoDialog
        title={""}
        open={extraInfoOpen}
        onClose={() => setExtraInfoOpen(false)}
        hideButton={true}
      >
        <form className={"w-100"}>
          <div className="relative mb-3">
            <input id="metros2" type="number"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder=" "
              value={extraData.metros2}
              onChange={e => setExtraData({...extraData, metros2: e.target.value})}/>
            <label htmlFor="metros2"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Metros cuadrados")}</label>
          </div>
          <div className="relative mb-3">
            <input id="palets" type="number"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              inputMode="numeric"
              pattern="[0-9]*"
              placeholder=" "
              value={extraData.palets}
              onChange={e => setExtraData({...extraData, palets: e.target.value})}/>
            <label htmlFor="palets"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Palets")}</label>
          </div>
          <div className="relative mb-3">
            <textarea id="promociones" key={"promociones"}
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              rows={5}
              placeholder=" "
              value={extraData.promociones}
              onChange={e => setExtraData({...extraData, promociones: e.target.value})}/>
            <label htmlFor="promociones"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/4 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Promociones")}</label>
          </div>
        </form>
        <div className={"w-full flex flex-row justify-center items-center"}>
          <button
            className={"w-full mt-3 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
            type="button" onClick={handleExtraDataClose}>
            {t("Confirmar")}
          </button>
        </div>
      </InfoDialog>
      <InfoDialog
        title={`Serie ${serie}`}
        open={additionalInfoOpen}
        onClose={() => setAdditionalInfoOpen(false)}
      >
        <div className={"flex flex-col justify-center items-center my-2"}>
          <span className={"text-xl uppercase text-center"}>{t("Colores")}</span>
          {colores.filter(c => !c.decorado).map(c => (
            <div className={"my-3 w-full"} key={c.imagen}>
              <div
                className={
                  "flex flex-col justify-center items-center my-2 sm:my-0"
                }
              >
                <span className={"my-2 text-md text-center font-bold uppercase"}>{c.color}</span>
                <img
                  className=" object-cover rounded-md shadow-drop-bottom h-20 md:h-20 w-full"
                  src={`${BASE_URL}/piezas/${c.imagen}.jpg`}
                  alt={c.color}
                />
              </div>
              <div
                className={
                  "flex flex-col justify-center items-center px-4 my-3 sm:my-0"
                }
              >
                <span className={"text-md uppercase text-center"}>{t("Formatos")}</span>
                <div className={"grid grid-cols-1 my-1 w-full"}>
                  {[...new Set(productos.filter(p => p.color === c.color).map(p => p.formato))].map(f =>
                    <span key={f}
                      className={
                        "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-md text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                      }
                    >
                      {f}
                    </span>
                  )}
                </div>
              </div>
              <div
                className={
                  "flex flex-col justify-center items-center px-4 my-3 sm:my-0"
                }
              >
                <span className={"text-md uppercase text-center"}>{t("Acabados")}</span>
                <div className={"grid grid-cols-1 my-1 w-full"}>
                  {[...new Set(productos.filter(p => p.color === c.color).map(p => p.acabado))].map(a =>
                    <span key={a}
                      className={
                        "px-3 py-1 my-1 mx-2 border border-gray-400 rounded-md text-gray-800 text-center text-sm uppercase cursor-default text-ellipsis overflow-hidden whitespace-nowrap"
                      }
                    >
                      {a}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </InfoDialog>
      <InfoDialog
        title={""}
        open={finishPopupOpen}
        onClose={handleFinish}
      >
        <p>{t("Producto añadido correctamente a la Wishlist")}</p>
      </InfoDialog>
    </div>
  )
}

export default SelectSerieProductsPage;