const API_URL = `${process.env.REACT_APP_API_BASE_URL}/users`;

class AuthService {
  login(username, password) {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/login`, {
        method: "POST",
        body: JSON.stringify({
          username,
          password
        }),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
        .then(r => r.json())
        .then(response => {
          if (response.token) {
            localStorage.setItem("user", JSON.stringify(response));
          }
          resolve(response)
        })
        .catch(reject)
    })
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  createUser = (newUserData) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/login`, {
        method: "POST",
        body: JSON.stringify(newUserData),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  verify = (username, password, activationKey) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/verify`, {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
          activationKey
        }),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  resetPassword = (username, password, resetKey) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/reset`, {
        method: "POST",
        body: JSON.stringify({
          username,
          password,
          resetKey
        }),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }

  forgotPassword = (username) => {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/forgot-password`, {
        method: "POST",
        body: JSON.stringify({
          username
        }),
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
      })
        .then(r => r.json())
        .then(response => resolve(response))
        .catch(reject)
    })
  }
}

export default new AuthService();