import React from "react";

function NotFoundPage() {

  return (
    <div className={"container mx-auto p-4"}>
      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center items-center"}>
          <h2 className="text-3xl font-bold leading-tight text-gray-800 uppercase my-3">404</h2>
          <p className="text-lg font-medium leading-tight text-gray-800 uppercase my-3">Página no encontrada</p>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;