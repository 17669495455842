import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment"
import "moment/locale/es"
import Header from "../../components/header";
import ConfirmDialog from "../../components/confirm-dialog";

const AdminComercialesPage = ({obtenerAdminComerciales, createComercial}) => {
  moment.locale("es")

  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [comercialesData, setComercialesData] = useState([])
  const [comercialesColumns, setComercialesColumns] = useState([])

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [comercialUsername, setComercialUsername] = useState("")
  const [formError, setFormError] = useState(null)


  useEffect(() => {
    const columns = [
      {
        name: "Comercial",
        selector: row => row.username,
        sortable: true
      },
      {
        name: "Total visitas",
        selector: row => row.totalVisitas,
        sortable: true
      },
      {
        name: "Última visita",
        selector: row => row.fechaUltimaVisita ? moment(row.fechaUltimaVisita).format("L LTS") : "-",
        sortable: true,
        sortFunction: (a, b) => !a.fechaUltimaVisita ? -1 : !b.fechaUltimaVisita ? 1 :  a.fechaUltimaVisita > b.fechaUltimaVisita ? 1 : -1
      }
    ];
    setComercialesColumns(columns)
  }, []);

  useEffect(() => {
    setLoading(true)
    obtenerAdminComerciales()
      .then((comerciales) => {
        setComercialesData(comerciales)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [obtenerAdminComerciales])

  const handleCreation = () => {
    if (comercialUsername.length < 3) {
      setFormError("El nombre de usuario debe tener mínimo 3 caracteres.")
      setConfirmOpen(true);
      return;
    }
    setConfirmOpen(false);
    setLoading(true)
    createComercial({username: comercialUsername})
      .then(() => {
        toast.success("El comercial se ha creado con éxito")
        obtenerAdminComerciales()
          .then((comerciales) => {
            setComercialesData(comerciales)
            setLoading(false)
          })
          .catch((e) => {
            console.error(e)
            setLoading(false)
          })
      })
      .catch(() => {
        toast.error("Ha ocurrido un error al crear el comercial")
        setLoading(false)
      })
    setComercialUsername("")
  }

  return (
    <>
      <Toaster toastOptions={{
        success: {
          iconTheme: {
            primary: "black",
            secondary: "white",
          },
        },
      }}/>
      <div className={"container mx-auto p-4"}>
        <Header onBack={history.goBack} />
        <div className={"flex justify-center my-5"}>
          <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center lowercase">comerciales</h2>
        </div>
        <div className={"container mx-auto p-4 max-w-screen-md"}>
          <button type="button" className={"my-4 px-4 py-3 rounded-md bg-neutral-400 text-white text-xs font-bold shadow-drop-bottom uppercase"}
            onClick={() => {setFormError(null); setComercialUsername(""); setConfirmOpen(true);}}>
            Crear comercial
          </button>
          <DataTable
            columns={comercialesColumns}
            data={comercialesData}
            pagination
            progressPending={loading}
          />
          <ConfirmDialog
            title="Crear comercial"
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            onConfirm={handleCreation}
            si={"Crear"}
            no={"Cancelar"}
          >
            <div className="relative mb-3">
              <input id="username" type="text"
                className="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                placeholder=" "
                value={comercialUsername}
                onChange={e => setComercialUsername(e.target.value)}/>
              <label htmlFor="username"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Usuario del comercial</label>
            </div>
            {formError && (
              <div className="pl-5 mb-3 text-normal text-red-700">
                {formError}
              </div>
            )}
          </ConfirmDialog>
        </div>
      </div>
    </>
  )
}

export default AdminComercialesPage;