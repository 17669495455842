import React, { useState } from "react"
import { HiOutlineTrash, HiOutlinePencil } from "react-icons/hi"
import ConfirmDialog from "./confirm-dialog"
import {useTranslation} from "react-i18next";

const WishlistSerie = ({ serie, eliminaSerie, editSerie }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const { t } = useTranslation()

  const handleDelete = () => {
    eliminaSerie(serie)
  }

  const handleEdit = () => {
    editSerie(serie)
  }

  return (
    <>
      <div className={"grid grid-cols-5 py-0.5"}>
        <div className={"col-span-4"}>
          <span className={"font-bold"}>{serie}</span>
        </div>
        <div className={"flex flex-row flex-wrap place-content-around"}>
          <div role={"button"} tabIndex={-1}
            onClick={handleEdit}
            onKeyDown={handleEdit}
          >
            <HiOutlinePencil/>
          </div>
          <div role={"button"} tabIndex={-1}
            onClick={() => setConfirmOpen(true)}
            onKeyDown={() => setConfirmOpen(true)}
          >
            <HiOutlineTrash/>
          </div>
        </div>
      </div>
      <ConfirmDialog
        title={t("Eliminar producto")}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleDelete}
      >
        {t("¿Está seguro de eliminar el elemento de la wishlist?")}
      </ConfirmDialog>
    </>
  )
}

export default WishlistSerie
