import React, {useState} from "react";

import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AuthService from "../../services/auth-service";

const isValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g; // eslint-disable-line

function LoginPage({displayRegistration, verification}) {
  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  })

  const [formErrors, setFormErrors] = useState({
    username: null,
    password: null,
  })

  const history = useHistory()
  const { t, i18n } = useTranslation()

  const { key } = useParams()

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang, () => {
      localStorage.setItem("pamesa-current-lang", lang)
    })
  }

  const handleRegisterSubmit = () => {
    history.push("/register")
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    const _formErrors = {
      username: null,
      password: null,
    }

    let error = false

    if (formData.username.length === 0 || !formData.username.match(isValidEmail)) {
      error = true
      _formErrors.username = "El usuario introducido no es correcto"
    }

    if (formData.password.length === 0) {
      error = true
      _formErrors.password = "Introduce una contraseña"
    }

    setFormErrors(_formErrors)

    if (!error) {
      setLoading(true)
      if (verification) {
        AuthService.verify(formData.username, formData.password, key)
          .then(({ visitaId }) => {
            if (visitaId) {
              window.location.replace(`/visita/${visitaId}`)
            } else {
              window.location.replace("/visitas")
            }
          })
          .catch(() => {
            setFormErrors({
              username: null,
              password: "Credenciales incorrectas"
            })
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        AuthService.login(formData.username, formData.password)
          .then(({ visitaId }) => {
            if (visitaId) {
              window.location.replace(`/visita/${visitaId}`)
            } else {
              window.location.replace("/visitas")
            }
          })
          .catch(() => {
            setFormErrors({
              username: null,
              password: "Credenciales incorrectas"
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }

  return (
    <div className={"container mx-auto h-screen p-4 max-w-screen-md"}>
      <div className={""}>
        <span role={"button"} tabIndex={-1} className={"px-1"} onClick={() => handleChangeLanguage("es")} onKeyDown={() => handleChangeLanguage("es")}>ES</span>
        <span role={"button"} tabIndex={-1} className={"px-1 border-l border-black"} onClick={() => handleChangeLanguage("en")} onKeyDown={() => handleChangeLanguage("en")}>EN</span>
        <span role={"button"} tabIndex={-1} className={"px-1 border-l border-black"} onClick={() => handleChangeLanguage("fr")} onKeyDown={() => handleChangeLanguage("fr")}>FR</span>
      </div>
      <div className={"w-full flex flex-col justify-center items-center"}>
        <img src={"/assets/images/logo.svg"} alt="logo" className={"w-3/5 md:w-2/5 lg:w-1/5 mt-3"}/>
      </div>
      <div className={"w-full flex flex-col justify-center items-center"}>
        <h2 className={"text-2xl text-gray-700 font-bold mb-5"}>Pamesa Wishlist</h2>
        <form className={"w-4/5 md:w-3/5 lg:w-1/2 my-5"}>
          <div className="relative mb-3">
            <input id="username" type="email"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["username"]}
              onChange={e => setFormData({...formData, username: e.target.value})}/>
            <label htmlFor="username"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email</label>
          </div>
          {formErrors.username && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.username)}
            </div>
          )}

          <div className="relative mb-3">
            <input id="password" type="password"
              className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
              placeholder=" "
              value={formData["password"]}
              onChange={e => setFormData({...formData, password: e.target.value})}/>
            <label htmlFor="password"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Contraseña")}</label>
          </div>
          {formErrors.password && (
            <div className="pl-5 mb-3 text-normal text-red-700">
              {t(formErrors.password)}
            </div>
          )}

          <button
            className={"w-full mt-3 px-4 py-3 rounded-md bg-redPamesa text-white text-md font-bold shadow-drop-bottom uppercase"}
            type="submit" onClick={handleLoginSubmit} disabled={loading}>
            <span className={"uppercase"}>{t("Empezar")}</span>
          </button>
          {!verification && (
            <div className={"w-full py-2 flex flex-col justify-center items-center"}>
              <Link className={"text-sm text-redPamesa hover:underline"} to={"/forgot-password"}>{t("¿Has olvidado tu contraseña?")}</Link>
            </div>
          )}
        </form>
        {displayRegistration && (
          <>
            <div className={"w-4/5 h-0 border-t-2 border-black"}></div>

            <div className={"w-4/5 md:w-3/5 lg:w-1/2 my-5 flex flex-col justify-center"}>
              <span className={"w-full text-center text-black text-md font-bold mt-3 mb-1"}>{t("¿Aún no tienes usuario?")}</span>
              <button
                className={"w-full mt-3 px-4 py-3 rounded-md bg-redPamesa text-white text-md font-bold shadow-drop-bottom uppercase"}
                type="submit" onClick={handleRegisterSubmit} disabled={loading || (verification && !key)}>
                <span className={"uppercase"}>{t("Registrarse")}</span>
              </button>
            </div>
          </>
        )}

      </div>
    </div>
  );
}

export default LoginPage;