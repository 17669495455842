import React, {useEffect, useState, useRef} from "react";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/auth-service";
import {useTranslation} from "react-i18next";

const CrearVisitaPage = ({crearVisita}) => {
  const [formData, setFormData] = useState({
    cliente: "",
    correo: "",
    telefono: "",
    language: "es",
    empresa: "",
    pais: "",
    provincia: "",
    sector: "",
    condiciones: false
  })

  const [formErrors, setFormErrors] = useState({
    cliente: null,
    correo: null,
    telefono: null,
    language: null,
    empresa: null,
    pais: null,
    provincia: null,
    sector: null,
    condiciones: null
  })

  const comerciales = [
    "agomez@pamesa.com",
    "aortega@pamesa.com",
    "ahernandez@pamesa.com",
    "cgutierrez@pamesa.com",
    "cayetano.garcia@pamesa.com",
    "egomez@pamesa.com",
    "esalazar@pamesa.com",
    "emistraletti@pamesa.com",
    "fathi@pamesa.com",
    "fortiz@pamesa.com",
    "hgrau@pamesa.com",
    "aoron@pamesa.com",
    "isaborit@pamesa.com",
    "imilian@pamesa.com",
    "jsalvador@pamesa.com",
    "jdiaz@pamesa.com",
    "jarodriguez@pamesa.com",
    "jasubies@pamesa.com",
    "jlcrespo@pamesa.com",
    "jmllaneza@pamesa.com",
    "jmclemente@pamesa.com",
    "jmateu@pamesa.com",
    "jrocha@pamesa.com",
    "lchaume@pamesa.com",
    "mantoli@pamesa.com",
    "mariagarcia@pamesa.com",
    "ngarcia@pamesa.com",
    "nramirez@pamesa.com",
    "nubeda@pamesa.com",
    "pcorreas@pamesa.com",
    "ppenas@pamesa.com",
    "pgil@pamesa.com",
    "pcayuela@pamesa.com",
    "ssanchez@pamesa.com",
    "sdonev@pamesa.com",
    "tcano@pamesa.com",
    "ajgasco@pamesa.com",
    "vroca@pamesa.com",
    "vgarcia@pamesa.com",
    "vmendez@pamesa.com",
    "carles@bokenhub.com",
    "sahilnegoser@hotmail.fr",
    "pedro.mg.costa@gmail.com",
    "lars@ljscanspain.com",
    "geraldine@tegim.be",
    "laurence@tegim.be",
    "charly@tegim.be",
    "info@nwaudek.com",
    "henry.nylund@nwaudek.com",
    "ewelina@comet-bv.com",
    "cretienne@comet-bv.com",
    "jeroen@comet-bv.com",
    "rick@comet-bv.com",
    "allerdave@gmail.com",
    "oscar@meglioli.eu",
    "d.zangonetile@gmail.com",
    "paolomontanari@mac.com",
    "matteo.twintiles@gmail.com",
    "tvk@stroysnabbusiness.com",
    "info@stroysnabbusiness.com",
    "igors.obuhovskis@gmail.com",
    "nasirov@formatoo.ru",
    "e.remond34@gmail.com",
    "augusto.ferreira@free.fr",
    "carrasco.olivier@gmail.com",
    "stephane.coudert@orange.fr",
    "audreybougeant@orange.fr",
    "jmateu@pamesa.com",
    "gcircati@gmail.com",
    "orel@held-agencies.com",
    "office@held-agencies.com",
    "alpisa@aol.com",
    "pnventalon@gmail.com",
    "e.ventalon@icloud.com",
    "hmarini@orange.fr",
    "agencecommercialebeaufort@orange.fr",
    "martinlaurent57@gmail.com",
    "yannick.prosdocimi@gmail.com",
    "clare.baldwin@cbtileagency.co.uk",
    "martin.baldwin@cbtileagency.co.uk",
    "GlennS@Armatile.com",
    "mastromarco.agente@gmail.com",
    "massimo.ruata@gmail.com",
    "info@rappresentanzemauro.it",
    "francesco.zucconi@tiscali.it",
    "gavidemasnc@gmail.com",
    "stefanopedrazzi1@gmail.com",
    "gmoncalieri@gmail.com",
    "luca.bertoni@alice.it",
    "agenziaromani@gmail.com",
    "antoniocosoleto58@gmail.com",
    "info@sintesiad.com",
    "info@agenziadistribuzione.com",
    "info@pellegrinorappresentanze.it",
    "alessandromusacco@gmail.com",
    "silvanodaloisio@alice.it",
    "office@mstfliesen.com",
    "zollinger@sariker.ch",
    "dri@sariker.ch",
    "hv-mittenentzwei@t-online.de",
    "agentur.spohler@gmail.com",
    "donath-guenter@t-online.de",
    "export@tileint.com",
    "thibault@mglexport.fr",
    "oparttiles@gmail.com",
    "kresimir.senjic@gmail.com",
    "bgtiles@gmail.com",
    "gkyprianou@sofocleskyprianou.com",
    "rocio@tiles.agency",
    "pkrol777@gmail.com"
  ]

  const [started, setStarted] = useState(false)
  const dataFetchedRef = useRef(false);

  const history = useHistory()
  const { t } = useTranslation()

  const user = AuthService.getCurrentUser().dataValues
  const comercialUsername = user.username

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (!comerciales.includes(comercialUsername)) {
      const rawProfile = localStorage.getItem("pamesa-wishlist-user-profile")
      if (!rawProfile) {
        history.replace("/visitas")
      } else {
        const profile = JSON.parse(rawProfile)
        crearVisita(comercialUsername, profile)
          .then((visitaId) => {
            history.replace(`/visita/${visitaId}`)
          })
          .catch((e) => {
            console.error(e)
          })
      }
    } else {
      setStarted(true)
    }
  }, [comercialUsername])

  const handleNewList = () => {
    let isErrored = false
    let _formErrors = {
      cliente: null,
      correo: null,
      telefono: null,
      language: null,
      empresa: null,
      pais: null,
      provincia: null,
      sector: null,
      condiciones: null
    }

    if (!formData.cliente || formData.cliente.length < 1) {
      _formErrors.cliente = {
        message: "Debe informar el campo"
      }
      isErrored = true
    }

    //eslint-disable-next-line
    if (formData.correo && formData.correo.length && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.correo))) {
      _formErrors.correo = {
        message: "La dirección no es correcta"
      }
      isErrored = true
    }

    /*
    if (!formData.correo || formData.correo.length < 1) {
      _formErrors.correo = {
        message: "Debe informar el campo"
      }
      isErrored = true
    //eslint-disable-next-line
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.correo))) {
      _formErrors.correo = {
        message: "La dirección no es correcta"
      }
      isErrored = true
    }
    */

    if (!formData.condiciones) {
      _formErrors.condiciones = {
        message: "Debe aceptar las condiciones"
      }
      isErrored = true
    }

    setFormErrors(_formErrors)
    if (!isErrored) {
      crearVisita(comercialUsername, formData)
        .then((visitaId) => {
          setFormData({
            cliente: "",
            correo: "",
            telefono: "",
            language: "es",
            empresa: "",
            pais: "",
            provincia: "",
            sector: "",
            condiciones: false
          })
          history.push(`/visita/${visitaId}`)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  return (
    started && (
      <div className={"container mx-auto p-4 max-w-screen-sm"}>
        <div className={"grid grid-cols-1 mb-5"}>
          <div className={"flex flex-column justify-center items-center"}>
            <h2 className="w-5/6 text-2xl font-medium text-black text-center">{t("Datos Cliente")}</h2>
          </div>
        </div>
        <div className={"grid grid-cols-1 mb-3"}>
          <div className={"flex flex-column justify-center items-center"}>
            <form className={"w-5/6 "}>
              <div className="relative mb-3">
                <input id="cliente" type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["cliente"]}
                  onChange={e => setFormData({...formData, cliente: e.target.value})}/>
                <label htmlFor="cliente"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Nombre")}</label>
              </div>
              {formErrors.cliente && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.cliente.message)}
                </div>
              )}

              <div className="relative mb-3">
                <input id="correo-electronico" type="email"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["correo"]}
                  onChange={e => setFormData({...formData, "correo": e.target.value})}/>
                <label htmlFor="correo-electronico"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("E-mail")}</label>
              </div>
              {formErrors.correo && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.correo.message)}
                </div>
              )}

              <div className="relative mb-3">
                <input id="telefono" type="tel"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["telefono"]}
                  onChange={e => setFormData({...formData, "telefono": e.target.value})}/>
                <label htmlFor="telefono"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Teléfono")}</label>
              </div>
              {formErrors.telefono && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.telefono.message)}
                </div>
              )}

              <div className="relative mb-3">
                <select id="language"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["language"]}
                  onChange={e => setFormData({...formData, "language": e.target.value})}>
                  <option value={"es"}>{t("Español")}</option>
                  <option value={"en"}>{t("Inglés")}</option>
                  <option value={"fr"}>{t("Francés")}</option>
                </select>
              </div>

              <div className="relative mb-3">
                <input id="empresa" type="text"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["empresa"]}
                  onChange={e => setFormData({...formData, "empresa": e.target.value})}/>
                <label htmlFor="empresa"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Empresa")}</label>
              </div>
              {formErrors.empresa && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.empresa.message)}
                </div>
              )}

              <div className="relative mb-3">
                <select id="pais"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["pais"]}
                  onChange={e => setFormData({...formData, "pais": e.target.value})}>
                  <option selected></option>
                  <option value={"ÁFRICA"}>{t("ÁFRICA")}</option>
                  <option value={"ALBANIA"}>{t("ALBANIA")}</option>
                  <option value={"ALEMANIA"}>{t("ALEMANIA")}</option>
                  <option value={"ANGOLA"}>{t("ANGOLA")}</option>
                  <option value={"ANTIGUA - CARIBE"}>{t("ANTIGUA - CARIBE")}</option>
                  <option value={"ANTILLAS HOLANDESAS"}>{t("ANTILLAS HOLANDESAS")}</option>
                  <option value={"ARABIA SAUDITA"}>{t("ARABIA SAUDITA")}</option>
                  <option value={"ARGELIA "}>{t("ARGELIA ")}</option>
                  <option value={"ARGENTINA"}>{t("ARGENTINA")}</option>
                  <option value={"ARMENIA"}>{t("ARMENIA")}</option>
                  <option value={"ASIA ORIENTAL"}>{t("ASIA ORIENTAL")}</option>
                  <option value={"AUSTRALIA"}>{t("AUSTRALIA")}</option>
                  <option value={"AUSTRIA"}>{t("AUSTRIA")}</option>
                  <option value={"AZERBAYAN"}>{t("AZERBAYAN")}</option>
                  <option value={"BAHAMAS"}>{t("BAHAMAS")}</option>
                  <option value={"BAHREIN"}>{t("BAHREIN")}</option>
                  <option value={"BANGLADESH"}>{t("BANGLADESH")}</option>
                  <option value={"BARBADOS"}>{t("BARBADOS")}</option>
                  <option value={"BELGICA"}>{t("BELGICA")}</option>
                  <option value={"BERMUDAS"}>{t("BERMUDAS")}</option>
                  <option value={"BIELORUSIA"}>{t("BIELORUSIA")}</option>
                  <option value={"BOLIVIA"}>{t("BOLIVIA")}</option>
                  <option value={"BOSNIA"}>{t("BOSNIA")}</option>
                  <option value={"BOTSWANA"}>{t("BOTSWANA")}</option>
                  <option value={"BRASIL"}>{t("BRASIL")}</option>
                  <option value={"BRUNEI"}>{t("BRUNEI")}</option>
                  <option value={"BULGARIA"}>{t("BULGARIA")}</option>
                  <option value={"BURKINA FASO"}>{t("BURKINA FASO")}</option>
                  <option value={"CABO VERDE"}>{t("CABO VERDE")}</option>
                  <option value={"CAMBOYA"}>{t("CAMBOYA")}</option>
                  <option value={"CAMERUN"}>{t("CAMERUN")}</option>
                  <option value={"CANADA"}>{t("CANADA")}</option>
                  <option value={"CHILE"}>{t("CHILE")}</option>
                  <option value={"CHINA"}>{t("CHINA")}</option>
                  <option value={"CHIPRE"}>{t("CHIPRE")}</option>
                  <option value={"COLOMBIA"}>{t("COLOMBIA")}</option>
                  <option value={"CONGO REP DEM"}>{t("CONGO REP DEM")}</option>
                  <option value={"COREA DEL SUR"}>{t("COREA DEL SUR")}</option>
                  <option value={"COSTA DE MARFIL"}>{t("COSTA DE MARFIL")}</option>
                  <option value={"COSTA RICA"}>{t("COSTA RICA")}</option>
                  <option value={"CRETA"}>{t("CRETA")}</option>
                  <option value={"CROACIA"}>{t("CROACIA")}</option>
                  <option value={"CUBA"}>{t("CUBA")}</option>
                  <option value={"DINAMARCA"}>{t("DINAMARCA")}</option>
                  <option value={"ECUADOR"}>{t("ECUADOR")}</option>
                  <option value={"EEUU"}>{t("EEUU")}</option>
                  <option value={"EGIPTO"}>{t("EGIPTO")}</option>
                  <option value={"EL SALVADOR"}>{t("EL SALVADOR")}</option>
                  <option value={"EMIRATOS ARABES"}>{t("EMIRATOS ARABES")}</option>
                  <option value={"ESCANDINAVIA . P NORDICOS"}>{t("ESCANDINAVIA . P NORDICOS")}</option>
                  <option value={"ESCOCIA"}>{t("ESCOCIA")}</option>
                  <option value={"ESLOVAQUIA"}>{t("ESLOVAQUIA")}</option>
                  <option value={"ESLOVENIA"}>{t("ESLOVENIA")}</option>
                  <option value={"ESPAÑA"}>{t("ESPAÑA")}</option>
                  <option value={"ESTONIA"}>{t("ESTONIA")}</option>
                  <option value={"ETIOPIA"}>{t("ETIOPIA")}</option>
                  <option value={"FILIPINAS"}>{t("FILIPINAS")}</option>
                  <option value={"FINLANDIA"}>{t("FINLANDIA")}</option>
                  <option value={"FRANCIA"}>{t("FRANCIA")}</option>
                  <option value={"GALES"}>{t("GALES")}</option>
                  <option value={"GAMBIA"}>{t("GAMBIA")}</option>
                  <option value={"GEORGIA"}>{t("GEORGIA")}</option>
                  <option value={"GHANA"}>{t("GHANA")}</option>
                  <option value={"GRANADA - ISLA CARIBE"}>{t("GRANADA - ISLA CARIBE")}</option>
                  <option value={"GRECIA"}>{t("GRECIA")}</option>
                  <option value={"GUADALUPE"}>{t("GUADALUPE")}</option>
                  <option value={"GUATEMALA"}>{t("GUATEMALA")}</option>
                  <option value={"GUAYANA"}>{t("GUAYANA")}</option>
                  <option value={"GUINEA ECUATORIAL"}>{t("GUINEA ECUATORIAL")}</option>
                  <option value={"GUYANA Y GUYANA FRANCESA"}>{t("GUYANA Y GUYANA FRANCESA")}</option>
                  <option value={"HAITI"}>{t("HAITI")}</option>
                  <option value={"HAWAII"}>{t("HAWAII")}</option>
                  <option value={"HOLANDA. P. BAJOS"}>{t("HOLANDA. P. BAJOS")}</option>
                  <option value={"HONDURAS"}>{t("HONDURAS")}</option>
                  <option value={"HONG KONG"}>{t("HONG KONG")}</option>
                  <option value={"HUNGRIA"}>{t("HUNGRIA")}</option>
                  <option value={"INDIA"}>{t("INDIA")}</option>
                  <option value={"INGLATERRA"}>{t("INGLATERRA")}</option>
                  <option value={"IRAN"}>{t("IRAN")}</option>
                  <option value={"IRAQ"}>{t("IRAQ")}</option>
                  <option value={"IRLANDA"}>{t("IRLANDA")}</option>
                  <option value={"IRLANDA DEL NORTE"}>{t("IRLANDA DEL NORTE")}</option>
                  <option value={"ISLANDIA"}>{t("ISLANDIA")}</option>
                  <option value={"ISLAS CAIMAN"}>{t("ISLAS CAIMAN")}</option>
                  <option value={"ISLAS MAURICIO"}>{t("ISLAS MAURICIO")}</option>
                  <option value={"ISLAS REUNION"}>{t("ISLAS REUNION")}</option>
                  <option value={"ISRAEL – ARABE"}>{t("ISRAEL – ARABE")}</option>
                  <option value={"ISRAEL – JUDIO"}>{t("ISRAEL – JUDIO")}</option>
                  <option value={"ITALIA"}>{t("ITALIA")}</option>
                  <option value={"JAMAICA"}>{t("JAMAICA")}</option>
                  <option value={"JAPON"}>{t("JAPON")}</option>
                  <option value={"JORDANIA"}>{t("JORDANIA")}</option>
                  <option value={"KAZAJISTAN"}>{t("KAZAJISTAN")}</option>
                  <option value={"KENIA"}>{t("KENIA")}</option>
                  <option value={"KIRGUISTAN"}>{t("KIRGUISTAN")}</option>
                  <option value={"KOREA"}>{t("KOREA")}</option>
                  <option value={"KOSOVO"}>{t("KOSOVO")}</option>
                  <option value={"KURGISTAN"}>{t("KURGISTAN")}</option>
                  <option value={"KUWAIT"}>{t("KUWAIT")}</option>
                  <option value={"LETONIA / LATVIA"}>{t("LETONIA / LATVIA")}</option>
                  <option value={"LIBANO"}>{t("LIBANO")}</option>
                  <option value={"LIBIA"}>{t("LIBIA")}</option>
                  <option value={"LITUANIA"}>{t("LITUANIA")}</option>
                  <option value={"LUXEMBURGO"}>{t("LUXEMBURGO")}</option>
                  <option value={"MACEDONIA"}>{t("MACEDONIA")}</option>
                  <option value={"MADAGASCAR"}>{t("MADAGASCAR")}</option>
                  <option value={"MALASIA"}>{t("MALASIA")}</option>
                  <option value={"MALAWI"}>{t("MALAWI")}</option>
                  <option value={"MALI"}>{t("MALI")}</option>
                  <option value={"MALTA"}>{t("MALTA")}</option>
                  <option value={"MARRUECOS"}>{t("MARRUECOS")}</option>
                  <option value={"MARTINICA"}>{t("MARTINICA")}</option>
                  <option value={"MAURICIO"}>{t("MAURICIO")}</option>
                  <option value={"MAURITANIA"}>{t("MAURITANIA")}</option>
                  <option value={"MAYOTTE"}>{t("MAYOTTE")}</option>
                  <option value={"MEXICO"}>{t("MEXICO")}</option>
                  <option value={"MOLDAVIA"}>{t("MOLDAVIA")}</option>
                  <option value={"MONGOLIA"}>{t("MONGOLIA")}</option>
                  <option value={"MONTENEGRO"}>{t("MONTENEGRO")}</option>
                  <option value={"MOZAMBIQUE"}>{t("MOZAMBIQUE")}</option>
                  <option value={"NAMIBIA"}>{t("NAMIBIA")}</option>
                  <option value={"NICARAGUA"}>{t("NICARAGUA")}</option>
                  <option value={"NÍGER/NIGERIA"}>{t("NÍGER/NIGERIA")}</option>
                  <option value={"NORUEGA"}>{t("NORUEGA")}</option>
                  <option value={"NUEVA  ZELANDA"}>{t("NUEVA  ZELANDA")}</option>
                  <option value={"NUEVA CALEDONIA"}>{t("NUEVA CALEDONIA")}</option>
                  <option value={"OMAN"}>{t("OMAN")}</option>
                  <option value={"PAISES BAJOS  - HOLANDA"}>{t("PAISES BAJOS  - HOLANDA")}</option>
                  <option value={"PAKISTAN"}>{t("PAKISTAN")}</option>
                  <option value={"PALESTINA"}>{t("PALESTINA")}</option>
                  <option value={"PANAMA"}>{t("PANAMA")}</option>
                  <option value={"PARAGUAY"}>{t("PARAGUAY")}</option>
                  <option value={"PERU"}>{t("PERU")}</option>
                  <option value={"POLONIA"}>{t("POLONIA")}</option>
                  <option value={"PORTUGAL"}>{t("PORTUGAL")}</option>
                  <option value={"PUERTO RICO"}>{t("PUERTO RICO")}</option>
                  <option value={"QATAR"}>{t("QATAR")}</option>
                  <option value={"REINO UNIDO"}>{t("REINO UNIDO")}</option>
                  <option value={"REPUBLICA CHECA"}>{t("REPUBLICA CHECA")}</option>
                  <option value={"RUANDA"}>{t("RUANDA")}</option>
                  <option value={"RUMANIA"}>{t("RUMANIA")}</option>
                  <option value={"RUSIA"}>{t("RUSIA")}</option>
                  <option value={"SAINT VINCENT - CARIBE"}>{t("SAINT VINCENT - CARIBE")}</option>
                  <option value={"SANTA LUCIA"}>{t("SANTA LUCIA")}</option>
                  <option value={"SENEGAL"}>{t("SENEGAL")}</option>
                  <option value={"SERBIA"}>{t("SERBIA")}</option>
                  <option value={"SINGAPUR"}>{t("SINGAPUR")}</option>
                  <option value={"SIRIA "}>{t("SIRIA ")}</option>
                  <option value={"SOMALIA"}>{t("SOMALIA")}</option>
                  <option value={"SUDAFRICA"}>{t("SUDAFRICA")}</option>
                  <option value={"SUDAMERICA"}>{t("SUDAMERICA")}</option>
                  <option value={"SUDAN"}>{t("SUDAN")}</option>
                  <option value={"SUECIA"}>{t("SUECIA")}</option>
                  <option value={"SUIZA"}>{t("SUIZA")}</option>
                  <option value={"TADJIKISTAN"}>{t("TADJIKISTAN")}</option>
                  <option value={"TAHITI"}>{t("TAHITI")}</option>
                  <option value={"TAILANDIA"}>{t("TAILANDIA")}</option>
                  <option value={"TAIWAN"}>{t("TAIWAN")}</option>
                  <option value={"TANZANIA"}>{t("TANZANIA")}</option>
                  <option value={"TOGO"}>{t("TOGO")}</option>
                  <option value={"TRINIDAD Y TOBAGO"}>{t("TRINIDAD Y TOBAGO")}</option>
                  <option value={"TUNEZ"}>{t("TUNEZ")}</option>
                  <option value={"TURCOS & CAICOS - CARIBE"}>{t("TURCOS & CAICOS - CARIBE")}</option>
                  <option value={"TURKMENISTAN"}>{t("TURKMENISTAN")}</option>
                  <option value={"TURQUIA"}>{t("TURQUIA")}</option>
                  <option value={"UCRANIA"}>{t("UCRANIA")}</option>
                  <option value={"UGANDA"}>{t("UGANDA")}</option>
                  <option value={"URUGUAY"}>{t("URUGUAY")}</option>
                  <option value={"UZBEKISTAN"}>{t("UZBEKISTAN")}</option>
                  <option value={"VENEZUELA"}>{t("VENEZUELA")}</option>
                  <option value={"VIETNAM"}>{t("VIETNAM")}</option>
                  <option value={"YEMEN"}>{t("YEMEN")}</option>
                  <option value={"ZIMBABWE"}>{t("ZIMBABWE")}</option>
                </select>
                <label htmlFor="pais"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("País")}</label>
              </div>
              {formErrors.pais && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.pais.message)}
                </div>
              )}

              <div className="relative mb-3">
                <select id="provincia"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["provincia"]}
                  onChange={e => setFormData({...formData, "provincia": e.target.value})}>
                  <option selected></option>
                  {formData.pais !== "ESPAÑA" && (
                    <option value={"N/A"}>N/A</option>
                  )}
                  {formData.pais === "ESPAÑA" && (
                    <>
                      <option value={"ALAVA -VITORIA"}>ALAVA -VITORIA</option>
                      <option value={"ALBACETE"}>ALBACETE</option>
                      <option value={"ALICANTE"}>ALICANTE</option>
                      <option value={"ALMERÍA"}>ALMERÍA</option>
                      <option value={"ANDORRA"}>ANDORRA</option>
                      <option value={"ASTURIAS - OVIEDO"}>ASTURIAS - OVIEDO</option>
                      <option value={"ÁVILA"}>ÁVILA</option>
                      <option value={"BADAJOZ"}>BADAJOZ</option>
                      <option value={"BALEARES"}>BALEARES</option>
                      <option value={"BARCELONA"}>BARCELONA</option>
                      <option value={"BURGOS"}>BURGOS</option>
                      <option value={"CÁCERES"}>CÁCERES</option>
                      <option value={"CÁDIZ"}>CÁDIZ</option>
                      <option value={"CANARIAS"}>CANARIAS</option>
                      <option value={"CANTABRIA -  SANTANDER"}>CANTABRIA -  SANTANDER</option>
                      <option value={"CASTELLÓN"}>CASTELLÓN</option>
                      <option value={"CEUTA"}>CEUTA</option>
                      <option value={"CIUDAD REAL"}>CIUDAD REAL</option>
                      <option value={"CÓRDOBA"}>CÓRDOBA</option>
                      <option value={"CUENCA"}>CUENCA</option>
                      <option value={"GERONA"}>GERONA</option>
                      <option value={"GRANADA"}>GRANADA</option>
                      <option value={"GUADALAJARA"}>GUADALAJARA</option>
                      <option value={"GUIPÚZCOA - SAN SEBASTIÁN"}>GUIPÚZCOA - SAN SEBASTIÁN</option>
                      <option value={"HUELVA"}>HUELVA</option>
                      <option value={"HUESCA"}>HUESCA</option>
                      <option value={"JAÉN"}>JAÉN</option>
                      <option value={"LA CORUÑA"}>LA CORUÑA</option>
                      <option value={"LA RIOJA -  LOGROÑO"}>LA RIOJA -  LOGROÑO</option>
                      <option value={"LEÓN"}>LEÓN</option>
                      <option value={"LERIDA"}>LERIDA</option>
                      <option value={"LUGO"}>LUGO</option>
                      <option value={"MADRID"}>MADRID</option>
                      <option value={"MÁLAGA"}>MÁLAGA</option>
                      <option value={"MELILLA"}>MELILLA</option>
                      <option value={"MURCIA"}>MURCIA</option>
                      <option value={"NAVARRA -  PAMPLONA"}>NAVARRA -  PAMPLONA</option>
                      <option value={"ORENSE"}>ORENSE</option>
                      <option value={"PALENCIA"}>PALENCIA</option>
                      <option value={"PONTEVEDRA"}>PONTEVEDRA</option>
                      <option value={"PORTUGAL – AZ. EL VADO"}>PORTUGAL – AZ. EL VADO</option>
                      <option value={"SALAMANCA"}>SALAMANCA</option>
                      <option value={"SEGOVIA"}>SEGOVIA</option>
                      <option value={"SEVILLA"}>SEVILLA</option>
                      <option value={"SORIA"}>SORIA</option>
                      <option value={"TARRAGONA"}>TARRAGONA</option>
                      <option value={"TERUEL"}>TERUEL</option>
                      <option value={"TOLEDO"}>TOLEDO</option>
                      <option value={"VALENCIA"}>VALENCIA</option>
                      <option value={"VALLADOLID"}>VALLADOLID</option>
                      <option value={"VIZCAYA - BILBAO"}>VIZCAYA - BILBAO</option>
                      <option value={"ZAMORA"}>ZAMORA</option>
                      <option value={"ZARAGOZA "}>ZARAGOZA </option>
                      <option value={"ZARAGOZA GENERAL"}>ZARAGOZA GENERAL</option>
                    </>
                  )}
                </select>
                <label htmlFor="provincia"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Región")}</label>
              </div>
              {formErrors.provincia && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.provincia.message)}
                </div>
              )}

              <div className="relative mb-3">
                <select id="sector"
                  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-black peer"
                  placeholder=" "
                  value={formData["sector"]}
                  onChange={e => setFormData({...formData, "sector": e.target.value})}>
                  <option selected></option>
                  <option value={"particular"}>{t("Particular")}</option>
                  <option value={"profesional"}>{t("Profesional")}</option>
                  <option value={"arquitecto"}>{t("Arquitecto")}</option>
                  <option value={"interiorista"}>{t("Interiorista")}</option>
                </select>
                <label htmlFor="sector"
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-[#F6F6F6] px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">{t("Sector")}</label>
              </div>
              {formErrors.sector && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.sector.message)}
                </div>
              )}

              <div className="relative mb-3">
                <input id="condiciones" type="checkbox" checked={formData["condiciones"]} onChange={e => setFormData({...formData, "condiciones": e.target.checked})}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>
                <label htmlFor="condiciones" className="ml-2 text-sm font-medium text-black">
                  {t("He leído y acepto las ")}<a href="https://www.pamesa.com/PROTECCIONDATOS.html?pd=contact" target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">{t("condiciones")}</a>.
                </label>
              </div>
              {formErrors.condiciones && (
                <div className="text-sm pl-5 mb-3 text-normal text-red-700">
                  {t(formErrors.condiciones.message)}
                </div>
              )}

              <div className={"w-full flex flex-row justify-center items-center"}>
                <button
                  className={"w-full mt-3 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
                  type="button" onClick={handleNewList}>
                  {t("Comenzar visita")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  )
}

export default CrearVisitaPage;