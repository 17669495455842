import React from "react";
import {useTranslation} from "react-i18next";

export default function ConfirmDialog(props) {
  const { open, onClose, title, children, onConfirm, onNoConfirm, si, no } = props
  if (!open) {
    return <></>
  }
  const { t } = useTranslation()

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-zinc-500/50 flex">
      <div className="relative p-8 bg-[#F6F6F6] w-5/6 max-w-md m-auto flex-col flex rounded-lg">
        <h2 className="text-xl font-medium leading-tight text-gray-800 uppercase">
          {t(title)}
        </h2>
        <div className="py-5 text-gray-800">{children}</div>
        <div className="flex justify-end">
          <div className="p-1">
            <button
              className={"min-w-min w-20 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
              onClick={() => {
                onClose()
                onConfirm()
              }}
            >
              {t(si) || t("Sí")}
            </button>
          </div>
          <div className="p-1">
            <button
              className={"min-w-min w-20 px-4 py-3 rounded-md bg-[#F6F6F6] border-2 border-black text-black text-sm tracking-widest shadow-drop-bottom uppercase"}
              onClick={() => {
                onClose()
                onNoConfirm && onNoConfirm()
              }}
            >
              {t(no) || t("No")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
