import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

function LangPage() {
  const [language, setLanguage] = useState(null)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const lang = localStorage.getItem("pamesa-current-lang")
    console.log(lang)
    if (lang) {
      setLanguage(lang)
    } else {
      setLanguage("es")
    }
  },[])

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang, () => {
      setLanguage(lang)
      localStorage.setItem("pamesa-current-lang", lang)
    })
  }

  return (
    <div className={"container mx-auto py-2 px-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-1"}>
          <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center uppercase">{t("Idioma")}</h2>
        </div>
      </div>
      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center items-center my-5"}>
          <button
            className={`w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase ${language === "es" ? "bg-redPamesa text-white" : "bg-zinc-400 text-gray-300"}`}
            type="button" onClick={() => {handleChangeLanguage("es")}}>
            Español
          </button>
          <button
            className={`w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase ${language === "en" ? "bg-redPamesa text-white" : "bg-zinc-400 text-gray-300"}`}
            type="button" onClick={() => {handleChangeLanguage("en")}}>
            English
          </button>
          <button
            className={`w-3/4 mt-5 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase ${language === "fr" ? "bg-redPamesa text-white" : "bg-zinc-400 text-gray-300"}`}
            type="button" onClick={() => {handleChangeLanguage("fr")}}>
            Français
          </button>
        </div>
      </div>
    </div>
  );
}

export default LangPage;