import React, { useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { HiOutlinePencil, HiOutlineMail } from "react-icons/hi";

import AuthService from "../../services/auth-service.js";
import InfoDialog from "../../components/info-dialog";

const HomePage = ({ obtenerVisitas, obtenerComercial, resendVisita }) => {
  const [visitasData, setVisitasData] = useState([])
  const [comercialData, setComercialData] = useState(null)

  const [popupOpen, setPopupOpen] = useState(false)

  const history = useHistory()
  const { t, i18n } = useTranslation()

  const user = AuthService.getCurrentUser().dataValues
  const comercialUsername = user.username

  useEffect(() => {
    localStorage.setItem("tau-pamesa-current-visita", -1)
  })

  useEffect(() => {
    obtenerVisitas(comercialUsername)
      .then((visitas) => {
        const clienteVisitas = {}
        visitas.forEach(v => {
          const visitaKey = `${v.correo}##${v.cliente}`
          if (!Object.keys(clienteVisitas).includes(visitaKey)) {
            clienteVisitas[visitaKey] = {
              cliente: v.cliente,
              empresa: v.empresa,
              visitas: [],
              ultimaVisita: null
            }
          }
          clienteVisitas[visitaKey].visitas.push(v)
          if (!clienteVisitas[visitaKey].ultimaVisita || v.fecha_creacion > clienteVisitas[visitaKey].ultimaVisita) {
            clienteVisitas[visitaKey].ultimaVisita = v.fecha_creacion
          }
        })

        const _visitas = Object.values(clienteVisitas);
        _visitas.forEach(v => {
          v.visitas = v.visitas.sort((a, b) => b.fecha_creacion > a.fecha_creacion ? 1 : -1)
        })

        setVisitasData(_visitas.sort((visitasA, visitasB) =>
          visitasB.ultimaVisita > visitasA.ultimaVisita ? 1 : -1
        ))
      })
      .catch((e) => {
        console.error(e)
      })
    obtenerComercial(comercialUsername)
      .then((comercial) => {
        setComercialData(comercial)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [comercialUsername, obtenerVisitas, obtenerComercial])

  const handleEdit = (visitaId) => {
    history.push(`/visita/${visitaId}`)
  }

  const handleResend = (visitaId) => {
    resendVisita(visitaId, i18n.language)
      .then(() => {setPopupOpen(true)})
  }

  return visitasData && comercialData && (
    <div className={"container mx-auto py-2 px-4 max-w-screen-md"}>
      <div className={"grid grid-cols-1"}>
        <div className={"flex justify-center my-1"}>
          <h2 className="text-2xl md:text-3xl font-medium font-pamesa-serif leading-tight text-black text-center uppercase">{t("Visitas")}</h2>
        </div>
      </div>
      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-row justify-center my-5"}>
          <Link to={"/crear-visita"} className={"px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}>
            {t("Crear visita")}
          </Link>
        </div>
      </div>
      <div className={"relative grid grid-cols-1 my-3"}>
        <div className={"flex flex-col justify-center divide-y divide-neutral-300"}>
          {visitasData && visitasData.map(visitas => (
            <>
              <div className={"grid grid-cols-1 py-0.5 mt-4 border-t-0"}>
                <span className={"text-neutral-900"}>{visitas.cliente}</span>
              </div>
              {visitas.visitas.map(visita => (
                <div key={visita.id} className={"grid grid-cols-5 py-0.5"}>
                  <div className={"col-span-4"}>
                    <span className={"font-bold"}>{moment(visita.fecha_creacion).format("L")}</span>
                  </div>
                  <div className={"flex flex-row justify-end items-center px-3"}>
                    {!visita.finalizada && (
                      <div role={"button"} tabIndex={-1}
                        onClick={() => handleEdit(visita.id)}
                        onKeyDown={() => handleEdit(visita.id)}
                      >
                        <HiOutlinePencil/>
                      </div>
                    )}
                    {visita.finalizada && (
                      <div role={"button"} tabIndex={-1}
                        onClick={() => handleResend(visita.id)}
                        onKeyDown={() => handleResend(visita.id)}
                      >
                        <HiOutlineMail/>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
      <InfoDialog
        title={""}
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
      >
        <p>{t("En breve recibirás un email con el resumen de tu visita.")}</p>
      </InfoDialog>
    </div>
  )
}

export default HomePage;