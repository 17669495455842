import React from "react";
import {useTranslation} from "react-i18next";

export default function InfoDialog(props) {
  const { open, onClose, title, hideButton, children } = props
  if (!open) {
    return <></>
  }

  const { t } = useTranslation()

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-zinc-500/50 flex">
      <div className="relative p-8 bg-[#F6F6F6] w-5/6 max-w-md m-auto flex-col flex rounded-lg">
        <h2 className="text-2xl font-medium leading-tight text-center text-gray-800 uppercase">
          {t(title)}
        </h2>
        <div className="py-5 text-gray-800">{children}</div>
        {!hideButton && (
          <div className="flex justify-end">
            <div className="p-1">
              <button
                className={"min-w-min w-20 px-4 py-3 rounded-md bg-redPamesa text-white text-sm font-bold shadow-drop-bottom uppercase"}
                onClick={() => {
                  onClose()
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
