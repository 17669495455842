import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Layout from "./components/layout";
import RouteGuard from "./components/router-gard";

import AdminPage from "./pages/AdminPage/admin-page";
import AdminVisitasPage from "./pages/AdminPage/admin-visitas-page";
import AdminComercialesPage from "./pages/AdminPage/admin-comerciales-page";
import AdminProductosPage from "./pages/AdminPage/admin-productos-page";

import LoginPage from "./pages/AuthPage/login-page";
import ResetPasswordPage from "./pages/AuthPage/reset-password-page";
import ForgotPasswordPage from "./pages/AuthPage/forgot-password-page";

import HomePage from "./pages/HomePage/home-page";
import CrearVisitaPage from "./pages/CrearVisitaPage/crear-visita-page";
import VisitaPage from "./pages/VisitaPage/visita-page";
import NotFoundPage from "./pages/NotFoundPage/not-found-page";
import ScanSeriePage from "./pages/ScanSeriePage/scan-serie-page";
import SelectSerieProductsPage from "./pages/SelectSerieProductsPage/select-serie-products-page";

import CrearUsuarioPage from "./pages/CrearVisitaPage/crear-usuario-page";
import SettingsPage from "./pages/UserPage/settings-page";
import ProfilePage from "./pages/UserPage/profile-page";
import LangPage from "./pages/UserPage/lang-page";

import {
  createComercial,
  eliminarAdminVisitas, obtenerAdminComerciales,
  obtenerAdminProductos,
  obtenerAdminVisitas
} from "./services/admin-services";

import {
  anadirProducto,
  anadirSerie,
  crearVisita,
  finalizarVisita,
  registroCliente,
  eliminarProducto,
  eliminarSerie,
  obtenerComercial,
  obtenerProductos,
  obtenerVisita,
  obtenerVisitas,
  resendVisita,
} from "./services/app-services";

import "./App.css";

function App() {
  const { i18n } = useTranslation()
  useEffect((() => {
    const lang = localStorage.getItem("pamesa-current-lang")
    if (lang) {
      if (lang === i18n.language) return;
      i18n.changeLanguage(lang)
    }

  }), [])

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage displayRegistration={true}/>
        </Route>
        <Route path="/login-comercial">
          <LoginPage displayRegistration={false}/>
        </Route>
        <Route path="/verify/:key">
          <LoginPage displayRegistration={false} verification={true}/>
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage/>
        </Route>
        <Route path="/reset-password/:key">
          <ResetPasswordPage/>
        </Route>
        <Route path="/register">
          <Layout>
            <CrearUsuarioPage registroCliente={registroCliente}/>
          </Layout>
        </Route>
        <RouteGuard adminRoute path="/admin/visitas">
          <AdminVisitasPage obtenerAdminVisitas={obtenerAdminVisitas} eliminarAdminVisitas={eliminarAdminVisitas}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin/comerciales">
          <AdminComercialesPage obtenerAdminComerciales={obtenerAdminComerciales} createComercial={createComercial}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin/productos">
          <AdminProductosPage obtenerAdminProductos={obtenerAdminProductos}/>
        </RouteGuard>
        <RouteGuard adminRoute path="/admin">
          <AdminPage/>
        </RouteGuard>
        <Route path="/visita/producto/:serie">
          <ScanSeriePage anadirSerie={anadirSerie}/>
        </Route>
        <RouteGuard path="/crear-visita">
          <Layout>
            <CrearVisitaPage crearVisita={crearVisita} />
          </Layout>
        </RouteGuard>
        <RouteGuard path="/visita/:visitaId/:serie">
          <Layout>
            <SelectSerieProductsPage obtenerProductos={obtenerProductos} anadirProducto={anadirProducto} obtenerVisita={obtenerVisita}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/visita/:visitaId">
          <Layout>
            <VisitaPage obtenerVisita={obtenerVisita} eliminarProducto={eliminarProducto} eliminarSerie={eliminarSerie} finalizarVisita={finalizarVisita}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/visitas">
          <Layout shouldNotDisplayBackButton={true}>
            <HomePage obtenerVisitas={obtenerVisitas} obtenerComercial={obtenerComercial} resendVisita={resendVisita}/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/user/settings">
          <Layout>
            <SettingsPage/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/user/profile">
          <Layout>
            <ProfilePage/>
          </Layout>
        </RouteGuard>
        <RouteGuard path="/user/language">
          <Layout>
            <LangPage/>
          </Layout>
        </RouteGuard>
        <Route path="/">
          <LoginPage/>
        </Route>
        <Route path="*" >
          <Layout>
            <NotFoundPage />
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
